<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">1.3 变更记录</div>
      <div class="table" style="margin-top: 0">
        <ChangeLogMoreTable
          :data="entityJson"
          :ChangeLogLength="this.ChangeLogLength"
        ></ChangeLogMoreTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getDueDiligenceModel006 } from "@/api/api";
import ChangeLogMoreTable from "@/pages/Result/GongshangquanjingReport/ChangeLog/components/ChangeLogMoreTable";
import { getDesensitizationFromRouter } from "@/utils/helper";
import { getChangeRecord } from "../../../../api/gongshangquanjing";

export default {
  name: "Changelog",
  props: {
    ChangeLogLength: Boolean,
  },
  components: {
    ChangeLogMoreTable,
  },
  data() {
    return {
      loaded: false,
      filters: [
        {
          col: 2,
          options: [
            {
              label: "全部类型",
              f: function (value) {
                return true;
              },
            },
            {
              label: "企业名称",
              f: function (value) {
                return value === "企业名称";
              },
            },
            {
              label: "注册资本",
              f: function (value) {
                return value === "注册资本";
              },
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getChangeRecord({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      return this.data || {};
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped></style>
