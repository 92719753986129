<template>
  <div class="table">
    <table>
      <tbody>
      <tr>
        <th></th>
        <th colspan="4">{{data.sq}}</th>
        <th colspan="4">{{data.bq}}</th>
      </tr>
      <tr>
        <th :style="guessThStyle('序号',0)">序号</th>
        <th colspan="2" :style="guessThStyle('采购商品',1)">采购商品</th>
        <th
          colspan="2"  :style="guessThStyle('销售商品',2)">销售商品
        </th>
        <th colspan="2" :style="guessThStyle('采购商品',3)">采购商品</th>
        <th colspan="2" :style="guessThStyle('销售商品',4)">销售商品</th>
      </tr>
      <tr  v-for="(row,index) in data.bodyList" :key="index">
        <td :style="guessTdStyle(col,index2)" v-for="(col,index2) in row" :key="index2">{{col}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
import TableMix from "@/mix/TableMix";

export default {
  name: "PurchaseAndSaleTable",
  props:{
    data:Object
  },
  mixins:[
    TableMix
  ]
}
</script>

<style scoped>

</style>
