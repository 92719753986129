<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">2.3 高管信息({{ total }})</div>
      <div class="xtable">
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide">
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '操作'">
              <button class="btn-link" @click="handleViewClick(record)">查看</button>
            </template>
          </template>
        </EasyTable>
        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else>
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '操作'">
              <button class="btn-link" @click="handleViewClick(record)">查看</button>
            </template>
          </template>
        </EasyTable2>
      </div>
    </div>
    <SeniorManagementDialog ref="seniorManagementDialog"></SeniorManagementDialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import XEUtils from "xe-utils";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

import { getBranch, getSeniorManagement } from "@/api/gongshangquanjing";
import SeniorManagementDialog from "@/pages/Result/GongshangquanjingReport/seniorManagement/SeniorManagementDialog.vue";

export default {
  name: "SeniorManagement",
  props: {
    hide: Boolean,
  },
  components: {
    SeniorManagementDialog,
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
      data: {},
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getSeniorManagement({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      const header = {
        序号: {
          value: "序号",
          key: "序号",
          resizable: true,
          minWidth: 100,
          width: 70,
          editorType: "text",
          textAlign: "center",
        },
        姓名: {
          value: "姓名",
          key: "姓名",
          resizable: true,
          minWidth: 100,
          // width: 300,
          editorType: "text",
        },
        职务: {
          value: "职务",
          key: "职务",
          resizable: true,
          minWidth: 100,
          editorType: "text",
        },
        持股数: {
          value: "持股数",
          key: "持股数",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
        },
        年龄: {
          value: "年龄",
          key: "年龄",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
        },
        学历: {
          value: "学历",
          key: "学历",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
        },
        操作: {
          value: "操作",
          key: "operation",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
          custom: true,
          fixed: "right",
        },
      };

      const data = cloneDeep(this.data);
      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    handleViewClick(record) {
      const index = parseInt(record["序号"].value) - 1;
      this.$refs["seniorManagementDialog"].open(this.data.subTables[index]);
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
  //height 400px
}
</style>
