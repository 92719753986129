<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">2.1 上市公告({{ total }})</div>
      <div class="xtable">
        <el-input
          style="margin: 10px auto"
          v-model="input"
          placeholder="请输入公告标题或正文中的关键词"
          @blur="handleInputSearchClick"
          v-show="this.hide"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleInputSearchClick"
          ></el-button>
        </el-input>
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide">
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '上市公告'">
              <a :href="cell.value.link" target="_blank">{{ cell.value.text }}</a>
            </template>
          </template>
        </EasyTable>

        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else>
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '上市公告'">
              <a :href="cell.value.link" target="_blank">{{ cell.value.text }}</a>
            </template>
          </template>
        </EasyTable2>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getListingAnnouncement } from "@/api/gongshangquanjing";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

export default {
  name: "ListedCompanyAnnouncement",
  props: {
    hide: Boolean,
  },
  components: {
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
      input: "",
      data: null,
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getListingAnnouncement({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      const header = {
        序号: {
          value: "序号",
          key: "序号",
          resizable: true,
          minWidth: 100,
          width: 70,
          editorType: "text",
          textAlign: "center",
        },
        日期: {
          value: "日期",
          key: "日期",
          resizable: true,
          width: 200,
          editorType: "text",
        },
        上市公告: {
          value: "上市公告",
          key: "上市公告",
          resizable: true,
          editorType: "text",
          custom: true,
        },
      };

      const data = cloneDeep(this.data);
      data.header = header;
      data.body = this.data.body.filter((item) => {
        if (typeof item["上市公告"].text == "string") {
          return item["上市公告"].text.indexOf(this.input) > -1;
        } else {
          return true;
        }
      });
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    handleInputSearchClick() {
      this.$refs["easyTable"].loadTableData();
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
}
</style>
