<template>
  <div class="title">
    <img
      src="@/assets/result/title_l.png"
      class="title-l"
    /><slot></slot><img
    src="@/assets/result/title_r.png"
    class="title-r"
  />
  </div>
</template>

<script>
export default {
  name: "BlockTitle"
}
</script>

<style lang="stylus" scoped>
.title {
  margin 40px auto 40px
  text-align: center;
  font-size: 32px;
  font-family: SourceHanSans-Regular, SourceHanSans;
  font-weight: 400;
  color: #030852;
  line-height: 47px;

.title-l {
  width: 144px;
  margin-right: 24px;
}

.title-r {
  width: 144px;
  margin-left: 24px;
}
}
</style>
