<template>
  <div class="more" >
    <div class="table">
      <div class="tableTop">纳税风险评估</div>
      <div v-if="isMoreThanStandard" class="float-table">
        <table>
          <tbody>
          <tr>
            <th style="width: 208px;">风险状况概览</th>
            <th style="width: 228px;">风险场景</th>
            <th style="width: 96px;">触发量</th>
            <th style="width: 608px;">风险点</th>
          </tr>
          <template v-for="(grp0,index) in data" >
            <template v-for="(grp1,index1) in grp0.children" >
              <template v-for="(grp2,index2) in grp1.children" >
                <tr :key="`${index}_${index1}_${index2}`">
                  <td :rowspan="getGrpRowSpan(grp0)" v-if="index1==0&&index2==0"><div  :style="guessTdStyle(grp0.titleName)">{{grp0.titleName}}</div> <div class="tip-box" v-if="grp0.tip">{{grp0.tip}}</div></td>
                  <td :rowspan="getGrpRowSpan(grp1)" v-if="index2==0"><div :style="guessTdStyle(grp1.titleName)">{{grp1.titleName}}</div> <div class="tip-box" v-if="grp1.tip">{{grp1.tip}}</div></td>
                  <td :rowspan="getGrpRowSpan(grp1)" v-if="index2==0"><div :style="{...guessTdStyle(grp1.riskSize),'text-align': 'center'}">{{grp1.riskSize}}</div> </td>
                  <td :style="guessTdStyle(grp2.titleName)">{{grp2.titleName}}</td>
                </tr>
              </template>
            </template>
          </template>
          <!--        <tr>-->
          <!--          <td class="solid">合计数量</td>-->
          <!--          <td></td>-->
          <!--          <td>37</td>-->
          <!--          <td></td>-->
          <!--        </tr>-->
          <!--        <tr v-for="(grp0,index) in data" :key="index">-->
          <!--          <td-->
          <!--            :rowspan="getGrpRowSpan(grp0)">{{ grp0.titleName }}-->
          <!--          </td>-->
          <!--          <td rowspan="4">1.1虚开发票风险</td>-->
          <!--          <td-->
          <!--            rowspan="4">4-->
          <!--          </td>-->
          <!--          <td>1.2.1顶额开票风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.2作废发票风险检测</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.3互相开票风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.4向社保人数为0的单位开具销售发票</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="5">-->
          <!--            <div>1.2接受虚开发票风险</div>-->
          <!--            <div class="label">建议重点关注</div>-->
          <!--          </td>-->
          <!--          <td rowspan="5">5</td>-->
          <!--          <td>1.3.1采购高风险商品风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.2从高风险地区采购商品分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3..3从成立不满6个月的企业采购商品分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.4取得"咨询服务费"发票风险</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.5取得"会议服务费"发票风险</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="11">2、企业财税风险分析-->
          <!--          </td>-->
          <!--          <td rowspan="2">2.1未按规定确认收入的风险</td>-->
          <!--          <td-->
          <!--            rowspan="2">2-->
          <!--          </td>-->
          <!--          <td>2.1.1固定资产变动率与收入变动率变动差异过大,存在未按规 定确认收入的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.1.2资产周转速度大幅下降,存在滞后确认收入的风险.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.2未按规定结转成本的风险-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.2.1营业成本变动率远高于营业收入变动率,存在多计成本、少-->
          <!--            计收入的风险.-->
          <!--          </td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.2.2应付账款与存货不配比,存在接受虚开发票的风险.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.3未按规定列支费用的风险</td>-->
          <!--          <td>1</td>-->
          <!--          <td>2.3.1投资收益大幅增长,利润波动不大,存在虚增费用的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.4存在调节利润的风险-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.4.1存货周转率远低于收入变动率,存在调节利润的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.4.2费用比对收入成本变动偏高,存在调节利润的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.5未按规定进行纳税申报的风险</td>-->
          <!--          <td>1</td>-->
          <!--          <td>2.5.1进项税额转出不足,存在未按规定申报的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.6未按规定进行账务处理的风险</td>-->
          <!--          <td>0</td>-->
          <!--          <td></td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.7其他提醒事项-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.7.1资产负债表与利润表不勾稽.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.7.2现金流量表与资产负债表现金增量不一致.</td>-->
          <!--        </tr>-->

          </tbody>
        </table>
      </div>
      <div class="scroller" ref="scroller">
        <table>
          <tbody>
          <tr>
            <th style="width: 208px;">风险状况概览</th>
            <th style="width: 228px;">风险场景</th>
            <th style="width: 96px;">触发量</th>
            <th style="width: 608px;">风险点</th>
          </tr>
          <template v-for="(grp0,index) in data" >
            <template v-for="(grp1,index1) in grp0.children" >
              <template v-for="(grp2,index2) in grp1.children" >
                <tr :key="`${index}_${index1}_${index2}`">
                  <td :rowspan="getGrpRowSpan(grp0)" v-if="index1==0&&index2==0"><div  :style="guessTdStyle(grp0.titleName)">{{grp0.titleName}}</div> <div class="tip-box" v-if="grp0.tip">{{grp0.tip}}</div></td>
                  <td :rowspan="getGrpRowSpan(grp1)" v-if="index2==0"><div :style="guessTdStyle(grp1.titleName)">{{grp1.titleName}}</div> <div class="tip-box" v-if="grp1.tip">{{grp1.tip}}</div></td>
                  <td :rowspan="getGrpRowSpan(grp1)" v-if="index2==0"><div :style="{...guessTdStyle(grp1.riskSize),'text-align': 'center'}">{{grp1.riskSize}}</div> </td>
                  <td :style="guessTdStyle(grp2.titleName)">{{grp2.titleName}}</td>
                </tr>
              </template>
            </template>
          </template>
          <!--        <tr>-->
          <!--          <td class="solid">合计数量</td>-->
          <!--          <td></td>-->
          <!--          <td>37</td>-->
          <!--          <td></td>-->
          <!--        </tr>-->
          <!--        <tr v-for="(grp0,index) in data" :key="index">-->
          <!--          <td-->
          <!--            :rowspan="getGrpRowSpan(grp0)">{{ grp0.titleName }}-->
          <!--          </td>-->
          <!--          <td rowspan="4">1.1虚开发票风险</td>-->
          <!--          <td-->
          <!--            rowspan="4">4-->
          <!--          </td>-->
          <!--          <td>1.2.1顶额开票风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.2作废发票风险检测</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.3互相开票风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.2.4向社保人数为0的单位开具销售发票</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="5">-->
          <!--            <div>1.2接受虚开发票风险</div>-->
          <!--            <div class="label">建议重点关注</div>-->
          <!--          </td>-->
          <!--          <td rowspan="5">5</td>-->
          <!--          <td>1.3.1采购高风险商品风险分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.2从高风险地区采购商品分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3..3从成立不满6个月的企业采购商品分析</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.4取得"咨询服务费"发票风险</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>1.3.5取得"会议服务费"发票风险</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="11">2、企业财税风险分析-->
          <!--          </td>-->
          <!--          <td rowspan="2">2.1未按规定确认收入的风险</td>-->
          <!--          <td-->
          <!--            rowspan="2">2-->
          <!--          </td>-->
          <!--          <td>2.1.1固定资产变动率与收入变动率变动差异过大,存在未按规 定确认收入的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.1.2资产周转速度大幅下降,存在滞后确认收入的风险.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.2未按规定结转成本的风险-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.2.1营业成本变动率远高于营业收入变动率,存在多计成本、少-->
          <!--            计收入的风险.-->
          <!--          </td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.2.2应付账款与存货不配比,存在接受虚开发票的风险.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.3未按规定列支费用的风险</td>-->
          <!--          <td>1</td>-->
          <!--          <td>2.3.1投资收益大幅增长,利润波动不大,存在虚增费用的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.4存在调节利润的风险-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.4.1存货周转率远低于收入变动率,存在调节利润的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.4.2费用比对收入成本变动偏高,存在调节利润的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.5未按规定进行纳税申报的风险</td>-->
          <!--          <td>1</td>-->
          <!--          <td>2.5.1进项税额转出不足,存在未按规定申报的风险。</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.6未按规定进行账务处理的风险</td>-->
          <!--          <td>0</td>-->
          <!--          <td></td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td-->
          <!--            rowspan="2">2.7其他提醒事项-->
          <!--          </td>-->
          <!--          <td rowspan="2">2</td>-->
          <!--          <td>2.7.1资产负债表与利润表不勾稽.</td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td>2.7.2现金流量表与资产负债表现金增量不一致.</td>-->
          <!--        </tr>-->

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TableMix from "@/mix/TableMix";

export default {
  name: "TaxRiskEvaluate",
  mixins:[TableMix],
  props: {
    data: Array
  },
  methods: {
    getGrpRowSpan(grp) {
      let rowspan = 0;

      function walk(node) {
        if (node.children && node.children.length > 0) {

          for (const child of node.children) {
            walk(child)
          }

        } else {
          rowspan = rowspan + 1
        }
      }

      walk(grp)
      return rowspan;
    }
  },
  mounted() {
    this.loaded=true;
  },
  data(){
    return {
      loaded:false
    }
  },
  computed:{
    isMoreThanStandard(){
      if(!this.loaded)
        return;
      return this.$refs.scroller.getBoundingClientRect().height>300
    }
  }
}
</script>

<style lang="stylus" scoped>
.more {
  margin-bottom 20px
  position relative;
  table {
    border-collapse:collapse
    margin-bottom 0 !important
    border 0 none !important
  }
  .float-table {
    padding-right 7px
    box-sizing border-box
    position absolute
    height 54px
    overflow hidden
    z-index 99
    width 100%
  }

  .scroller {
    max-height 90vh
    overflow-y auto
    border-bottom 1px solid #D9D9D9
    border-left 1px solid #D9D9D9
  }

}

table {
  border-collapse:collapse
  margin-bottom 0 !important
}

.fixed {
  position absolute
  z-index 2
  width 100%
}

.label
  display inline-block;
  border 1px solid #F5222D;
  border-radius 0px
  padding 2px 5px
  margin-top 10px
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F5222D;
  line-height: 20px;
  justify-items center
  flex-grow 0

.tip-box
  font-size: 14px;
  padding 2px 8px
  color: #F5222D;
  background: rgba(245, 34, 45, 0.08);
  border-radius: 0px;
  border: 1px solid #F5222D;
  display inline-block
  margin-top 8px
</style>
