<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">1.5 分支机构({{ total }})</div>
      <div class="xtable">
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide"> </EasyTable>
        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else> </EasyTable2>
      </div>
    </div>
  </div>
</template>

<script>
import EasyTable from "../../components/EasyTable.vue";
import EasyTable2 from "../../components/EasyTable2.vue";

import { getBranch } from "../../../../api/gongshangquanjing";
import { cloneDeep } from "lodash";
import XEUtils from "xe-utils";

export default {
  name: "Branch",
  props: {
    hide: Boolean,
  },
  components: {
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getBranch({ programId: this.$route.params.programId });

    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      const header = {
        序号: {
          value: "序号",
          key: "序号",
          resizable: true,
          minWidth: 100,
          width: 70,
          editorType: "text",
          textAlign: "center",
        },
        企业名称: {
          value: "企业名称",
          key: "企业名称",
          resizable: true,
          minWidth: 100,
          width: 360,
          editorType: "text",
        },
        负责人: {
          value: "负责人",
          key: "负责人",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
        },
        成立日期: {
          value: "成立日期",
          key: "成立日期",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
        },
        全部经营状态: {
          value: "全部经营状态",
          key: "全部经营状态",
          resizable: true,
          minWidth: 100,
          width: null,
          editorType: "text",
          filter: {
            // 获得自定义筛选选项,并去重
            options: this.data.body
              .map((row) => {
                return {
                  label: row["全部经营状态"],
                  value: row["全部经营状态"],
                };
              })
              .reduce((pre, cur) => {
                pre.some((item) => item.value === cur.value) ? "" : pre.push(cur);
                return pre;
              }, []),
            handler({ value, row, column, filters }) {
              const ret = filters.includes(value);
              //const ret = XEUtils.toValueString(row['全部经营状态'].value).toLowerCase().indexOf(value) > -1

              return ret;
            },
          },
        },
      };

      // this.data.header.forEach((name, index) => {
      //   let width = null
      //   if (index == 0) {
      //     width = 70
      //   }
      //   if (index == 1) {
      //     width = 600
      //   }
      //   if (index == this.data.header.length - 1) {
      //     width = null
      //   }
      //   header[name] = {
      //     value: name,
      //     key: name,
      //     resizable: true,
      //     minWidth: 100,
      //     width,
      //     editorType: 'text',
      //   }
      // })
      const data = cloneDeep(this.data);
      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
  //height 400px
}
</style>
