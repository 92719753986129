<template>
  <div class="changeRecord">
    <div class="left" :style="'height:' + height + 'px'">
      <el-menu
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :default-openeds="openeds"
      >
        <el-submenu index="1" class="ioc">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">1 企业背景</span>
          </template>

          <a href="#BasisSection">
            <el-menu-item index="1-1">1.1基本信息</el-menu-item>
          </a>
          <a href="#ShareHolderAndManagementInfo">
            <el-menu-item index="1-2">1.2股东及管理人员信息</el-menu-item>
          </a>
          <a href="#ChangeLog">
            <el-menu-item index="1-3">1.3变更记录</el-menu-item>
          </a>
          <a href="#OutboundInvestment">
            <el-menu-item index="1-4">1.4对外投资</el-menu-item>
          </a>
          <a href="#Branch">
            <el-menu-item index="1-5">1.5分支机构</el-menu-item>
          </a>
        </el-submenu>

        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">2 上市公司信息</span>
          </template>
          <a href="#ListedCompanyAnnouncement">
            <el-menu-item index="2-1"> 2.1上市公告</el-menu-item>
          </a>
          <a href="#TopTenShareHolders">
            <el-menu-item index="2-2"> 2.2十大股东</el-menu-item>
          </a>
          <a href="#SeniorManagement">
            <el-menu-item index="2-3">2.3高管信息</el-menu-item>
          </a>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">3 风险预警</span>
          </template>
          <a href="#RiskWarning">
            <el-menu-item index="3-1">3.1经营异常</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-2">3.2行政处罚</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-3">3.3严重违法</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-4">3.4税收违法</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-5">3.5欠税公告</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-6">3.6被执行人</el-menu-item>
          </a>
          <a href="#RiskWarning">
            <el-menu-item index="3-7">3.7失信人</el-menu-item>
          </a>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">4 公司发展</span>
          </template>
          <a href="#CompanyBusiness">
            <el-menu-item index="4-1"> 4.1企业业务</el-menu-item>
          </a>
          <a href="#FinancingHistory">
            <el-menu-item index="4-2"> 4.2融资历史</el-menu-item>
          </a>
          <a href="#InvestHistory">
            <el-menu-item index="4-3">4.3投资事件</el-menu-item>
          </a>
          <a href="#AdministrativeLicense">
            <el-menu-item index="4-4">4.4行政许可</el-menu-item>
          </a>
          <a href="#ImportsAndExports">
            <el-menu-item index="4-5">4.5进出口信用</el-menu-item>
          </a>
        </el-submenu>

        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">5 公开正面信息</span>
          </template>
          <a href="#PublicPositiveInformation">
            <el-menu-item index="5-1"> 5.1企业资质</el-menu-item>
          </a>
          <a href="#PublicPositiveInformation">
            <el-menu-item index="5-2"> 5.2知识产权</el-menu-item>
          </a>
          <a href="#PublicPositiveInformation">
            <el-menu-item index="5-3"> 5.3招投标</el-menu-item>
          </a>
        </el-submenu>
      </el-menu>
      <div class="open">
        <button @click="(isCollapse = true), close()" v-if="!isCollapse" class="openBtn">
          <i class="iconfont iconxiangzuo" style="color: #061178"></i>
        </button>
        <button
          @click="(isCollapse = false), open()"
          v-else
          class="openBtn"
          style="margin-left: 18px"
        >
          <i class="iconfont iconxiangyou" style="margin-left: 18px; color: #061178"></i>
        </button>
      </div>
    </div>
    <!-- <div class="body-wrapper pages1" ref="pageRef" :style="'height:' + height + 'px;'"> -->
    <div class="body-wrapper pages1">
      <div class="jinzhi-report-wrapper" id="pdfBody">
        <div class="head-wrapper">
          <div class="title-wrapper">
            <div class="title">{{ data.name }}</div>
            <div class="sub-title">工商全景报告</div>
            <div class="date">报告日期：{{ this.createTime }}</div>
          </div>
        </div>
        <BlockTitle> 一、企业背景 </BlockTitle>
        <!-- 基本信息 -->
        <BasisSection id="BasisSection"></BasisSection>
        <!--股东及管理人员信息 -->
        <ShareHolderAndManagementInfo
          id="ShareHolderAndManagementInfo"
        ></ShareHolderAndManagementInfo>
        <!-- 变更记录部分 -->
        <ChangeLog
          id="ChangeLog"
          class="changeSome"
          :ChangeLogLength="this.ChangeLogLength"
        ></ChangeLog>

        <!-- 对外投资 -->
        <OutboundInvestment id="OutboundInvestment" :hide="this.hidePage"></OutboundInvestment>
        <!-- 分支结构 -->
        <Branch id="Branch" :hide="this.hidePage"></Branch>

        <BlockTitle> 二、上市公司信息 </BlockTitle>
        <!-- 上市公告 -->
        <ListedCompanyAnnouncement
          id="ListedCompanyAnnouncement"
          :hide="this.hidePage"
        ></ListedCompanyAnnouncement>
        <!-- 十大股东 -->
        <TopTenShareHolders id="TopTenShareHolders" :hide="this.hidePage"></TopTenShareHolders>
        <!-- 高管信息 -->
        <SeniorManagement id="SeniorManagement" :hide="this.hidePage"></SeniorManagement>

        <BlockTitle id="RiskWarning"> 三、风险预警 </BlockTitle>
        <RiskWarning id="RiskWarning" :hide="this.hidePage"></RiskWarning>

        <BlockTitle> 四、公司发展</BlockTitle>
        <!-- 企业业务 -->
        <CompanyBusiness id="CompanyBusiness" :hide="this.hidePage"></CompanyBusiness>
        <!-- 融资历史 -->
        <FinancingHistory id="FinancingHistory" :hide="this.hidePage"></FinancingHistory>
        <!-- 投资事件 -->
        <InvestHistory id="InvestHistory" :hide="this.hidePage"></InvestHistory>
        <!-- 行政许可 -->
        <AdministrativeLicense
          id="AdministrativeLicense"
          :hide="this.hidePage"
        ></AdministrativeLicense>
        <!--  进出口信用-->
        <ImportsAndExports id="ImportsAndExports" :hide="this.hidePage"></ImportsAndExports>

        <BlockTitle id="BlockTitle"> 五、公开正面信息 </BlockTitle>
        <PublicPositiveInformation
          id="PublicPositiveInformation"
          :hide="this.hidePage"
        ></PublicPositiveInformation>
      </div>
    </div>
    <div class="backLoad">
      <button type="text" class="onLoadPDFBtn" style="margin-bottom: 20px">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'businessCirclesQuery',
          }"
        >
          <i class="iconfont iconhuidaoshouye"></i>
        </router-link>
      </button>

      <button @click="onLoadPDF" type="text" class="onLoadPDFBtn">
        <i class="iconfont iconxiazaiPDF"></i>
      </button>
    </div>
    <loading
      :active.sync="pdfLoading"
      backgroundColor="#fff"
      :can-cancel="false"
      :is-full-page="true"
      loader="Dots"
      color="#2f54eb"
    ></loading>
    <!--  -->
    <!-- <PublicPositiveInformation></PublicPositiveInformation> -->
    <!--        <AllPurchaseAndSaleLine></AllPurchaseAndSaleLine>-->
    <!--        <AllPurchaseAndSaleMatching></AllPurchaseAndSaleMatching>-->
    <!--        <PurchaseInfo></PurchaseInfo>-->
    <!--        <SalesInfo></SalesInfo>-->
    <!--        <TaxCondition></TaxCondition>-->
    <!--        <KeyCostCondition></KeyCostCondition>-->
    <!--        <BlockTitle> 企业财务情况 </BlockTitle>-->
    <!--        <div class="warning-tip">-->
    <!--          本部分内容数据来自{{ data.zqSection }}该企业纳税申报数据，包括发票、纳税申报表、财务报表。-->
    <!--        </div>-->
    <!--        <BalanceCondition></BalanceCondition>-->
    <!--        <RevenueCostProfitChangeCondition></RevenueCostProfitChangeCondition>-->
    <!--        <div class="tail">-->
    <!-- 本报告由税安科技（杭州）有限公司提供，本报告仅供参考，不得用作法律诉讼的依据。未经税安科技同意或授权，不得向第三方透露本报告任何内容。在任何情况下，对由于使用本报告所造成的损失，税安科技不承担任何责任。 -->
    <!-- </div> -->
  </div>
</template>

<script>
import BlockTitle from "@/pages/Result/components/BlockTitle";
import BasisSection from "@/pages/Result/GongshangquanjingReport/BasisSection";
import PublicPositiveInformation from "@/pages/Result/PublicPositiveInformation/PublicPositiveInformation";

import RiskWarning from "@/pages/Result/GongshangquanjingReport/RiskWarning";
import ShareHolderAndManagementInfo from "@/pages/Result/GongshangquanjingReport/ShareHolderAndManagementInfo";
import OutboundInvestment from "@/pages/Result/GongshangquanjingReport/OutboundInvestment";
import ChangeLog from "@/pages/Result/GongshangquanjingReport/ChangeLog/ChangeLog";
import AllPurchaseAndSaleLine from "@/pages/Result/GongshangquanjingReport/AllPurchaseAndSaleLine";
import AllPurchaseAndSaleMatching from "@/pages/Result/GongshangquanjingReport/AllPurchaseAndSaleMatching/AllPurchaseAndSaleMatching";
import PurchaseInfo from "@/pages/Result/GongshangquanjingReport/PurchaseInfo";
import SalesInfo from "@/pages/Result/GongshangquanjingReport/SalesInfo";
import TaxCondition from "@/pages/Result/GongshangquanjingReport/TaxCondition";
import KeyCostCondition from "@/pages/Result/GongshangquanjingReport/KeyCostCondition";
import BalanceCondition from "@/pages/Result/GongshangquanjingReport/BalanceCondition/BalanceCondition";
import RevenueCostProfitChangeCondition from "@/pages/Result/GongshangquanjingReport/RevenueCostProfitChangeCondition";
import JindiaoShowShareCode from "@/pages/Result/GongshangquanjingReport/JindiaoShowShareCode";
import { getResultBasicInfo } from "../../../api/gongshangquanjing";
import Branch from "./branch/Branch.vue";
import ListedCompanyAnnouncement from "@/pages/Result/GongshangquanjingReport/ListedCompanyAnnouncement.vue";
import TopTenShareHolders from "@/pages/Result/GongshangquanjingReport/TopTenShareHolders.vue";
import SeniorManagement from "@/pages/Result/GongshangquanjingReport/seniorManagement/SeniorManagement.vue";
import jinZhiReportMenu from "@/pages/Result/GongshangquanjingReport/jinZhiReportMenu.vue";
import CompanyBusiness from "@/pages/Result/GongshangquanjingReport/CompanyBusiness.vue";
import FinancingHistory from "@/pages/Result/GongshangquanjingReport/FinancingHistory.vue";
import InvestHistory from "@/pages/Result/GongshangquanjingReport/InvestHistory.vue";
import AdministrativeLicense from "@/pages/Result/GongshangquanjingReport/AdministrativeLicense.vue";
import ImportsAndExports from "@/pages/Result/GongshangquanjingReport/importsAndExports/ImportsAndExports.vue";
import { getInfo } from "@/api/api";

import pdfManage from "../../../utils/pdf-manage";
import dateFormat from "../../../utils/date.js";
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "GongshangquanjingReport",
  components: {
    ImportsAndExports,
    AdministrativeLicense,
    InvestHistory,
    CompanyBusiness,
    SeniorManagement,
    FinancingHistory,
    JindiaoShowShareCode,
    jinZhiReportMenu,
    BlockTitle,
    BasisSection,
    PublicPositiveInformation,
    RiskWarning,
    ShareHolderAndManagementInfo,
    OutboundInvestment,
    ChangeLog,
    AllPurchaseAndSaleLine,
    AllPurchaseAndSaleMatching,
    PurchaseInfo,
    SalesInfo,
    TaxCondition,
    KeyCostCondition,
    BalanceCondition,
    RevenueCostProfitChangeCondition,
    Branch,
    ListedCompanyAnnouncement,
    TopTenShareHolders,
    Loading,
  },
  data() {
    return {
      height: document.documentElement.clientHeight,
      data: {},
      loaded: false,
      programId: this.$route.params.programId,
      content: "",
      isCollapse: false,
      drawer: false,
      pageNum: 12,
      pageSize: 14,
      openeds: ["1", "2", "3", "4", "5"],
      createTime: "",
      isCollapse: false,
      openeds: ["1", "2", "3", "4", "5"],
      key: "",
      /* 控制分页显示隐藏 */
      hidePage: true,
      /* 加载状态 */
      pdfLoading: false,
      /* 变更记录 */
      ChangeLogLength: false,
    };
  },

  async mounted() {
    this.loaded = false;
    const res = await getResultBasicInfo({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
    const res2 = await getInfo(this.programId);
    this.createTime = res2.data.createTime;
  },
  methods: {
    //侧边栏展开收起
    handleOpen(key, keyPath) {
      const w = this.$refs.attrRef.offsetWidth;
      this.nextTick(function () {
        // console.log("获取元素宽高23", w);
      });
    },
    handleClose(done) {
      done();
    },
    goDetail(id) {
      window.location.hash = "";
      window.location.hash = name;
    },
    close() {
      document.getElementsByClassName("jinzhi-report-wrapper")[0].style.width = "80%";
    },
    open() {
      document.getElementsByClassName("jinzhi-report-wrapper")[0].style.width = "60%";
    },

    /* 打印PDF */
    onLoadPDF() {
      this.$confirm(`确定打印PDF工商全景报告吗?`, "温馨提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
      })
        .then(async () => {
          this.pdfLoading = true;
          this.$message({
            message: "正在努力生成pdf中,预计一分钟左右,请勿离开当前页面。",
            type: "success",
          });
          this.hidePage = false;
          this.ChangeLogLength = true;
          this.pdfLoading = true;
          setTimeout(() => {
            this.loadPDF();
          }, 1000);
        })
        .catch(() => {});
    },

    async loadPDF() {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      var time = new Date();
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      // var h = time.getHours();
      // var mm = time.getMinutes();
      // var s = time.getSeconds();
      const formatDate = y + "-" + add0(m) + "-" + add0(d);
      // y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(s);

      const pdfma = pdfManage(1, {
        type: "a4",
        bgColors: ["#ffffff"], //将白色作为可截断的背景色
        padding: [10, 10, 10, 10],
      });
      const res = await pdfma.save(`工商全景报告-${this.data.name}-${formatDate}.pdf`);
      if (res.code == 200) {
        /* 显示分页 */
        // this.hidePage = true;
        /* loading状态 */
        this.pdfLoading = false;
        /* 刷新页面 */
        location.reload();
      }

      /* 原生方法2 */
      // document.getElementById("pdfBody").focus();
      // window.print();
    },
  },
};
</script>

<style lang="stylus" scoped>
.body-wrapper
  background: #fff;
  flex: 1
  box-sizing: border-box;
  overflow: auto;
.jinzhi-report-wrapper
  text-align justify;
  border-radius: 0px;
  width:60%;
  box-sizing border-box
  margin 0px auto;
  background #fff;

  .head-wrapper
    display flex
    align-items center;
    justify-content center;
    margin-top 80px
    position relative;

    .title-wrapper
      text-align center;
      .title
        color #282828
        font-size: 40px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        line-height: 59px;
        letter-spacing: 4px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

      .sub-title
        margin-top 20px
        font-size: 20px;
        text-align center;
        color #666

      .date
        font-size: 14px;
        margin-top 20px
        color #999
        text-align center


    .link-view-body
      color #207DFB
      position absolute;
      right 20px;
      top 50%;
      font-size 24px

>>> .el-tabs__header
  margin-bottom 0

>>> .section-wrapper
  margin-bottom 40px

>>> .el-radio-group
  .el-radio-button__inner
    padding 6px 23px
    line-height 1.5
    color rgba(0, 0, 0, 0.4);

  .el-radio-button__orig-radio:checked + .el-radio-button__inner
    border: 1px solid #061178;
    background #fff
    color rgba(6, 17, 120, 1)
    box-shadow none

>>> .section-title
  padding 0 24px;
  box-sizing border-box
  width: 100%
  height: 64px;
  display flex;
  align-items center
  text-align center
  background: #061178;
  border-radius: 0px;
  color: #FFFFFF !important
  font-size: 24px;
  line-height: 32px;

>>> .tableTop
  background: #D6E4FF;
  padding 14px 26px
  color: #030852;

>>> .table
  width 100%
  border-radius 0px
  margin-top 16px
  overflow hidden

  table
    border-top 1px solid #D9D9D9;
    border-left 1px solid #D9D9D9;
    margin-bottom 16px
    width 100%;
    border-spacing 0;

    th {
      text-align center
      white-space: nowrap;
    }

    th, .solid {
      background: #F0F5FF;
    }

    .center {
      text-align center
    }

    td, th {
      line-height 1.5
      border-bottom 1px solid #D9D9D9;
      border-right 1px solid #D9D9D9;

      padding 16px
      box-sizing border-box
      font-weight: 400;
      font-size: 14px;
    }

    td {
      background: #FBFBFB;
    }

>>> .el-tabs--card > .el-tabs__header .el-tabs__nav
  display flex
  align-items flex-start
  border 0 none;

>>> .el-tabs--card > .el-tabs__header .el-tabs__item {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  text-align center
  width 146px;
  border-left 0 none
  padding 16px 24px
  font-weight: 500;
  height auto;
  line-height 1.5
}

>>> .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #10239E;
  border-bottom 2px solid #10239E
  height auto;
  line-height 1.5
  font-size 16px
}

>>> .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:focus {
  box-shadow none
}

.warning-tip
  background: rgba(250, 173, 20, 0.1);
  border: 1px solid #C08000;
  font-weight: 400;
  color: #C08000;
  padding 24px
  line-height: 24px;
  margin-bottom 40px

.tail
  font-size: 16px;
  font-weight: 400;
  color: #8B8B8B;
  line-height: 24px;
  letter-spacing: 1px;
  position relative
  top -16px

>>> .btn-more:hover {
  color #fff !important;
  background #2f54eb !important;
}

.changeRecord {
  display: flex;
  justify-content: space-between;
  width:100%;
}
.left {
  display: flex;
  justify-content: space-between
  position fixed
  overflow: auto;
  top: 0;
  left: 0px;
  bottom: 0;
  .el-menu--collapse {
    width: 1px;

    .el-submenu {
      display: none;
    }
  }
}
.el-radio-button__inner {
  padding 6px 13px
}
.open {
  border: none;
  margin-top: 205px;
  margin-left: -18px;
  margin-right: 8px;
  z-index:1;
  line-height 28px
  display: flex;
  flex-direction: column
  border-radius: 50%;
  button{
    border:none;
    border-radius: 50%;
  }
  .iconfont{
    font-size: 32px;
    border-radius: 50%;
  }
}
.openBtn{
  transition: transform 0.6s;
  transform: rotate(180deg)
  background:#fff;
  padding:0;

}
.open:hover .iconfont{
  border-radius: 50%;
  font-size: 32px;
  //text-shadow: 0px 0px 3px rgba(0,0,0,.2);
  //-webkit-box-shadow: 3px 3px 3px #000;
  box-shadow:0px 0px 14px #d5d0d0f2;;
}


.open >>> label .el-radio-button__inner {
  padding: 1px 8px;
}

>>>.el-menu-vertical-demo{
  width:230px;

}
//打印按钮
.backLoad{
  position: fixed;
  top:240px;
  right:40px;
  z-index 99
  cursor pointer
  display: flex;
  flex-direction: column;
  .iconfont{
    font-size: 40px;
    color:#061178;
  }
  .onLoadPDFBtn{
    border: none;
    background: none;

  }
}

.el-radio-button__inner {
  padding 6px 13px
}

.el-menu-vertical-demo >>> .el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
}

.ioc .el-submenu__title .el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
  top: 2px
}

.el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
  top: 2px
}


/* 设置垂直滚动条的宽度和水平滚动条的高度 */
::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

/* 设置滚动条的滑轨 */
::-webkit-scrollbar-track {
	background-color: #fff;
}

/* 滑块 */
::-webkit-scrollbar-thumb{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width:2px;
}
a{
  text-decoration: none;
}


.some >>>.more .scroller{
  max-height:100%;
}

@media print {
  .changeSome {
    display: none;
  }
  .change{
    display: block;
  }

}

.el-menu-vertical-demo {
  overflow-y auto
  transition all
}
</style>
