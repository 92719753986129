// import { reject, resolve } from 'core-js/fn/promise';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
// import { Message } from 'element-ui';
/**
 * 获取色值对应的rgba
 * @param {String}} val 色值
 * @returns r,g,b,a
 */
function getRgbaData(val) {
  let rgbaVal = val;
  if (/^#/g.test(rgbaVal)) {
    if (rgbaVal.length < 7) {
      throw new Error('请使用6位hex格式');
    }
    let a = rgbaVal.slice(1, 3);
    let b = rgbaVal.slice(3, 5);
    let c = rgbaVal.slice(5, 7);
    rgbaVal =
      'rgb(' +
      parseInt(a, 16) +
      ',' +
      parseInt(b, 16) +
      ',' +
      parseInt(c, 16) +
      ')';
  }
  //RGB(A)颜色转换为HEX十六进制的颜色值
  let regRgba = /rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3})(,([.\d]+))?\)/, //判断rgb颜色值格式的正则表达式，如rgba(255,20,10,.54)
    rsa = rgbaVal.replace(/\s+/g, '').match(regRgba);
  if (rsa) {
    let ala = rsa[5] === undefined ? '1' : Number(rsa[5]);
    let ala255 = Math.floor(ala * 255);
    return [Number(rsa[1]), Number(rsa[2]), Number(rsa[3]), ala255];
  } else {
    throw new Error('不符合rgab格式');
  }
}

async function makeHtmlToCanvas(el) {

  // const eleList = document.querySelectorAll(el);

  // if (!eleList) throw new Error('当前节点（' + el + '）不存在');
  let eleList = []
  for (let i = 1; i <= el; i++) {
    eleList.push(document.querySelector('.pages' + i))
  }
//console.log(eleList);

  //const eleList = domlist;

  let canvasList = [];
  let canvasList2 = []
  let promiseList = [];
  eleList.forEach(ele => {
   
    const eleW = ele.offsetWidth || 100; // 获得该容器的宽
    const eleH = ele.scrollHeight; // 获得该容器的高
    const eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
    const eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离
    const canvas = document.createElement('canvas');
    let abs = 0;
    const winIn =
      document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
    const winOut = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
    if (winOut > winIn) {
      // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
      abs = (winOut - winIn) / 2; // 获得滚动条宽度的一半
    }
    canvas.width = eleW * 2; // 将画布宽&&高放大两倍
    canvas.height = eleH * 2;
    const context = canvas.getContext('2d');
    if (context) {
      context.scale(2, 2); // 增强图片清晰度
      context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
    }


    const p = html2canvas(ele, {
      // dpi: '300',
      useCORS: true, // 允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
      allowTaint: true,
      scale: .7,
      // scale: window.devicePixelRatio , // 增加清晰度
      // taintTest: true,
      height: ele.offsetHeight,
      width: ele.offsetWidth,
      windowWidth: document.body.scrollWidth,
      windowHeight: document.body.scrollHeight
    })

    promiseList.push(p);

  });

  return Promise.all(promiseList);

}

/**
 * pdf管理对象
 * @param {String} selector class、id选择器
 * @param {Object} options 配置参数
 * @property {String} options.type 尺寸类型 a3/a4 对应尺寸为[297mm, 420mm]/[210mm,297mm]
 * @property {Array} options.bgColors 背景色数组，用于判断此色号是否作为可分割线,需要传入6位hex数值或rgba，如#ffffff,rgab(255,255,255,1)
 * @property {Array} options.padding 内容与页面边距，单位mm，支持参数合并，例[10, 15]/[10]/[10,15,10,15]
 * @returns {Object} manage pdf管理对象
 * @property {Function} manage.save 导出pdf
 */
export default function pdfManage(selector, options = {}) {
  let {
    bgColors,
    type,
    padding
  } = options;
  let paddingTop, paddingLeft, paddingRight, paddingBottom;
  bgColors = bgColors || ['#ffffff']; //过滤背景色数组默认赋值
  let types = ['a3', 'a4'];
  //类型对应尺寸mm
  let typeRect = {
    a3: {
      width: 297,
      height: 420
    },
    a4: {
      width: 210,
      height: 297
    }
  };
  type = type || 'a4'; //类型默认赋值
  //校验类型是否符合
  if (!types.includes(type)) {
    throw new Error('type只能是' + types.toString() + '中的一种');
  }
  padding = padding || [0]; //padding默认赋值
  //校验padding格式是否符合
  if (!Array.isArray(padding) || ![1, 2, 4].includes(padding.length)) {
    throw new Error('padding不符合格式');
  }
  //校验padding必须为0或正数
  if (padding.some(v => v < 0 || (!v && v != 0))) {
    throw new Error('padding不小于0');
  }
  //根据padding参数数量，分配四个位置对应的padding值
  switch (padding.length) {
    //四个位置padding相同
    case 1:
      paddingTop = paddingLeft = paddingRight = paddingBottom = padding[0];
      break;
    //上下padding取数组第一位，左右padding取数组第二位
    case 2:
      paddingTop = paddingBottom = padding[0];
      paddingLeft = paddingRight = padding[1];
      break;
    //数组位置分别对应上、右、下、左的padding
    case 4:
      [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding;
      break;
    default:
      break;
  }
  const pdf = new JsPDF('p', 'mm', type); // A4纸，纵向
  let bgColorsList = [];
  bgColors.forEach(color => {
    bgColorsList.push(JSON.stringify(getRgbaData(color)));
  });


  let managePromise = new Promise(resolve => {

    makeHtmlToCanvas(selector).then(canvasList => {
      /* 新增pdf下载-start */
      pdf.page = 0;
      let index = 1
      canvasList.forEach((canvas, pageIndex) => {
        //console.log(pageIndex);
        if (pdf.page != 0) {
          pdf.addPage()
        }
        var ctx = canvas.getContext('2d');
        if (pageIndex == 0 || pageIndex == canvasList.length - 1) {
          let paddingLeft2=paddingLeft
          let paddingRight2=paddingRight
          let paddingTop2=paddingTop
          let paddingBottom2=paddingBottom
          paddingLeft2=paddingRight2=paddingTop2=paddingBottom2=1

          var typeW = typeRect[type].width- paddingLeft2 - paddingRight2;
          var typeH = typeRect[type].height- paddingTop2 - paddingBottom2; // A4大小，210mm x 297mm，首页尾页完全显示,边框为1
        } else {
          var typeW = typeRect[type].width - paddingLeft - paddingRight;
          var typeH = typeRect[type].height - paddingTop - paddingBottom; // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
        }

        var imgHeight = Math.ceil((typeH * canvas.width) / typeW); // 按A4显示比例换算一页图像的像素高度
        var renderedHeight = 0;
        // pdf.addImage(
        //   canvas.toDataURL('image/jpeg', 1.0),
        //   'JPEG',
        //   paddingLeft,
        //   paddingTop,
        //   typeW,
        //   Math.min(typeH, (typeW * canvas.height) / canvas.width)
        // );
        // pdf.addPage();
        // ++pdf.page;
        while (renderedHeight < canvas.height) {
          //创建canvas存放每页的图片
          var page = document.createElement('canvas');
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight); // 可能内容不足一页
          var pageCtx = page.getContext('2d');
          // 用getImageData剪裁指定区域，并画到前面建立的canvas对象中
          let minHeight = Math.min(imgHeight, canvas.height - renderedHeight);

         
          let imgData = ctx.getImageData(
            0,
            renderedHeight,
            canvas.width,
            minHeight
          );
          let loopIndex = minHeight;

          //从下往上一行行数据做遍历，查找可以作为截断的行号（当整行的像素点都与背景色相符，则代表此行可以用来截断）
          while (loopIndex > 0 && bgColorsList.length > 0) {
            let bgFitCount = 0;
            //每四个数字，分别代表r，g，b，a的值
            for (
              let i = (loopIndex - 1) * 4 * imgData.width; i < loopIndex * 4 * imgData.width; i += 4
            ) {
              let imgDataI = [
                imgData.data[i],
                imgData.data[i + 1],
                imgData.data[i + 2],
                imgData.data[i + 3]
              ];
              //当前可用于过滤的背景色列表包含了此像素点
              
              if (bgColorsList.includes(JSON.stringify(imgDataI))) {
                bgFitCount += 1;
              } else {

                break;
              }
            }
            //找到符合条件的行，退出遍历
            if (bgFitCount === imgData.width) {
              //console.log("zhaodaol"+bgFitCount);
              break;
            } else {
              //未找到，继续遍历上一行
              loopIndex -= 1;

              //console.log("不符合条件宽度"+bgFitCount);
            }
          }
          //遍历完整页数据，还未找到可以用于截断的行，则不做处理，按默认情况截断
          if (loopIndex <= 0) {

           // console.log("没有找到空白");

            loopIndex = minHeight;
          }
          imgData = ctx.getImageData(0, renderedHeight, canvas.width, loopIndex);
          pageCtx.putImageData(imgData, 0, 0);
          //当前页被截断过，则需要在某尾添加被截断高度的填充色（bgColors[0]）作为补充
          if (loopIndex < minHeight ) {
            let fillBgHeight = minHeight - loopIndex;
            pageCtx.beginPath();
            pageCtx.fillStyle = bgColors[0];
            pageCtx.fillRect(0, loopIndex, canvas.width, fillBgHeight);
          }

          // Message.success(`正在输出第${pdf.page}张图片`)
          //将处理好的图片正式输出到pdf中
          if (pageIndex == 0 || pageIndex == canvasList.length - 1) {
            //首页尾页全屏输出
            pdf.addImage(
              page.toDataURL('image/jpeg', 1.0),
              'JPEG',
              1,
              1,
              typeW,
              Math.min(typeH, (typeW * page.height) / page.width)
            );
          } else {
            pdf.addImage(
              page.toDataURL('image/jpeg', 1.0),
              'JPEG',
              paddingLeft,
              paddingTop,
              typeW,
              Math.min(typeH, (typeW * page.height) / page.width)
            );
          }

          //本页渲染完成，继续处理下一页
          renderedHeight += loopIndex;
          // debugger
          if (renderedHeight < canvas.height) {
            pdf.addPage();
          } // 若是后面还有内容，添加一个空页
          ++pdf.page;
          ++index
          // delete page
        }
      })
      resolve();
    });
  });

  let manage = {
    el: selector,
    //保存pdf
    save(pdfFileName) {
      return new Promise(resolve => {
        managePromise.then(() => {
          pdf.save(pdfFileName);
          resolve({ code: 200 })
        });
      })

    },

    //输出pdf
    output(type, options) {
      managePromise.then(() => {
        pdf.output(type, options);
      });
    },
    //获取pdf宽度
    getPageWidth() {
      pdf.getPageWidth();
    },
    //获取pdf高度
    getPageHeight() {
      pdf.getPageHeight();
    },
    //添加元素
    addContent(el, options = {}) {
      managePromise = new Promise(resolve => {
        makeHtmlToCanvas(el).then(canvas => {
          let {
            x,
            y,
            pages
          } = options;
          x = x || 0;
          y = y || 0;
          pages = pages || 'all';
          if (pages !== 'all' && !Array.isArray(pages)) {
            throw new Error('options参数pages只能为all或者数字型数组');
          }
          for (let i = 0; i < pdf.page; i++) {
            if (pages !== 'all' && !pages.includes(i + 1)) {
              continue;
            }
            pdf.setPage(i + 1);
            pdf.addImage(
              canvas.toDataURL('image/jpeg', 1.0),
              'JPEG',
              x,
              y,
              canvas.width / 2,
              canvas.height / 2
            );
          }
          resolve();
          // pdf.save('123');
        });
      });
    }
  };
  return manage;
}