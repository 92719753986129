<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="480px"
      height="302px"
      :close-on-click-modal="false"
    >
      <div class="wrapper">
        <div class="icon el-icon-s-opportunity"></div>
        <el-form :rules="rules" ref="form" label-position="left" :model="form"
                 class="content" label-width="60px" :hide-required-asterisk="true">
          <div class="title">安全提示</div>
          <div class="text">检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。</div>
          <el-form-item label="验证码有效期：" label-width="110px" prop="expireHours">
            <el-input
              class="input"
              v-model.trim.number="form.expireHours"
              placeholder="请输入1-48的整数"
            >
              <div slot="suffix">小时</div>
            </el-input>
          </el-form-item>
          <div class="tuomin">
            <el-checkbox v-model="form.desensitization" v-if="desensitizationAvailable">
              对报告中关键的公司名称、税号、地址进行脱敏
            </el-checkbox>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
            <button
              @click="handleConfirmClick"
              class="btn btn-ok"
            >
              复制链接及验证码
            </button>
          </span>
    </el-dialog>
    <div v-if="isMobile" class="mobileShareCodeBtnWrapper">
      <img src="@/assets/result/mobile-share-report.png" class="shareCodeBtn"
           @click="handleShareCodeBtnWrapperClick"/>
    </div>
    <template v-else>
      <div class="verticleShareCodeBtnWrapper" v-if="vertical">
        <img src="@/assets/result/verticle-share-report.png" class="shareCodeBtn"
             @click="handleShareCodeBtnWrapperClick"/>
      </div>

      <div class="shareCodeBtnWrapper" v-else>
        <img src="@/assets/result/share-report.png" class="shareCodeBtn"
             @click="handleShareCodeBtnWrapperClick"/>
      </div>
    </template>
  </div>
</template>


<script>
import {
  checkShareCode,
  createShareCode,
  getDesensitizationInfo, getDesensitizationStatus,
  updateDesensitizationInfo
} from "@/api/api";
import copy from 'copy-to-clipboard';
import {getAutoRoutePushTo, getDesensitizationFromRouter} from "@/utils/helper";
import {getResultBasicInfo} from "../../../api/gongshangquanjing";

export default {
  name: "JindiaoShowShareCode",
  props: {
    uuid: String,
    companyName: String,
    vertical: Boolean,
    isMobile: Boolean
  },
  data() {
    return {
      checkList: [],
      dialogVisible: false,
      form: {
        desensitization: false,
        shareCode: '',
        expireHours: 1
      },
      rules: {
        expireHours: [
          {required: true, message: '请输入验证码有效期', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (Number.isInteger(value) && value >= 1 && value <= 48) {
                return callback();
              } else {
                return callback(new Error('请输入1-48的整数'));
              }
            }, trigger: 'blur'
          }
        ],
      },
      error: false,
      desensitizationAvailable: false,
      basicInfo: {}
    };
  },
  async mounted() {
    const token = localStorage.getItem("authorization");
    const res = await getDesensitizationInfo({token}); //是否修改过报告 修改过就不能下载脱敏报告

    const resBasicInfo = await getResultBasicInfo({programId: this.$route.params.programId})
    this.basicInfo = resBasicInfo.data

    this.desensitizationAvailable = true
    this.form.desensitization = !!res.data.desensitizationStatus

  },
  computed: {
    nsrsbh() {
      return this.$route.query.nsrsbh
    },
    detailRouterObject() {
      return getAutoRoutePushTo(this.uuid, this.$route, 0)
    }
  },
  methods: {
    handleDetailReportClick() {
      this.$router.push(this.detailRouterObject)
    },

    handleShareCodeBtnWrapperClick() {
      this.dialogVisible = true;
    },
    async handleConfirmClick() {

      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const token = localStorage.getItem("authorization");
          const res = await createShareCode(this.uuid, this.nsrsbh, this.form.expireHours)
          await updateDesensitizationInfo({
            token, desensitizationStatus: this.form.desensitization ? 1 : 0
          })
          let props = this.$router.resolve({
            name: 'GongshangquanjingReport-share',
            params: {
              uuid: this.$route.params.uuid,
              checkId: res.data.urlCheck
            },
            query: {
              ...this.$route.query,
              desensitization: this.form.desensitization ? 1 : 0
            }
          });

          const sharedUrl = new URL(props.href, window.location.href).href;
          const companyName = this.form.desensitization ? this.basicInfo.desensitizationCompanyName : this.basicInfo.companyName
          if (res.msgCode == 1) {
            const text = `报告名称：${companyName}-尽职调查报告
报告地址：${sharedUrl}
校验码：${res.data.checkCode}
有效期截止：${res.data.effectiveDate}`;
            copy(text);
            this.dialogVisible = false
            this.$message('复制成功');
          } else {
            this.error = true;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    }
  }
};
</script>
<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width 100%

  .icon {
    color: rgb(250, 173, 20);
    font-size: 32px;
  }

  .content {
    margin-left: 16px;
    margin-right 30px

    .title {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      font-size: 16px;
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      line-height: 1.5;
      margin-bottom 24px
    }

    .tuomin {
      margin-top 10px
      margin-bottom 10px

      .el-checkbox {
        margin-bottom 5px
      }

      .text {
        margin-bottom 10px
      }
    }

    .error {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #C30505;
      line-height: 20px;
      margin-top 16px
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding 0
  margin 0;

  .btn-ok {
    width 100%
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    margin 0
    border-radius 0
    line-height: 68px;
    padding 0
    background: #2F54EB;
  }
}

>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog__body {
  margin-bottom 0;
  padding-bottom 10px;
}

>>> .el-dialog__footer {
  padding 0
}

.mobileShareCodeBtnWrapper {
  position fixed;
  right 0;
  top 55px;
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 54px
  }
}

.verticleShareCodeBtnWrapper {
  position fixed;
  right 0;
  top 50%;
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    transform translateY(-180px)
    width 40px
  }
}

.shareCodeBtnWrapper {
  position fixed;
  right 1%;
  top 50%;
  transform translateY(-50%)
  width 160px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 100%;
    margin-top 58px
    transition all 0.5s ease-in-out
  }

  .shareCodeBtn:first-child {
    margin-top 0
  }

  .shareCodeBtn:hover {
    //transform: rotate(360deg);
    width 100%;
  }
  font-size 16px
}

</style>
