<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">
        六. {{ entityJson.name }}
      </div>
      <div class="tip" v-if="entityJson.data.text">
        {{ entityJson.data.text }}
      </div>
      <div class="table">
        <div class="scroll">
          <table>
            <tbody>
            <tr>
              <template v-for="(header,index) in entityJson.data.jsonData.header">
                <th style="width: 148px;" :key="index+'a'" :class="{fixed:index===0}" v-if="index===0">
                  <div class="padding">
                    <div class="out">
                      <b>月份</b>
                      <em>费用（万元）</em>
                      <span class="line"></span>
                    </div>
                  </div>

                </th>
                <th style="width: 148px" :key="index">
                  <div class="out" v-if="index===0">
                    <b>月份</b>
                    <em>费用（万元）</em>
                    <span class="line"></span>
                  </div>
                  <div v-else>
                    {{ header }}
                  </div>
                </th>
              </template>

            </tr>
            <tr v-for="(row,key) in entityJson.data.jsonData.body" :key="key">
              <td :class="{solid:head=='类别',fixed:index2===0}" v-if="index2===0" :key="index2+'a'"
                  v-for="(head,index2) in entityJson.data.jsonData.header"
                  :style="guessTdStyle(row[head])">{{ row[head] }}
              </td>
              <td :class="{solid:head=='类别'}" :key="index2"
                  v-for="(head,index2) in entityJson.data.jsonData.header"
                  :style="guessTdStyle(row[head])">{{ row[head] }}
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDueDiligenceModel012} from "@/api/api";
import TableMix from "@/mix/TableMix";
import {getDesensitizationFromRouter} from "@/utils/helper";

export default {
  name: "KeyCostCondition",
  mixins: [TableMix],
  data() {
    return {
      activeName: '0',
      data: null,
      loaded: false
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getDueDiligenceModel012({
      uuid: this.$route.params.uuid,
      desensitizationStatus: getDesensitizationFromRouter()
    })
    this.data = res.data
    this.loaded = true
  },
  computed: {
    entityJson() {
      return this.data ? this.data.entityJson : {}
    },
    tip() {
      return this.entityJson.text && this.entityJson.text.replace(/\n/g, "<br/>")
    }
  },
}
</script>

<style lang="stylus" scoped>
.scroll
  overflow-y auto

/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F0F5FF;
}

/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #F0F5FF;
}

/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D6E4FF;
}

table
  table-layout fixed

  .fixed
    position absolute
    width 148px
    overflow hidden
    z-index 2

    .padding
      position relative
      padding 10px 0
      height: 100%;

  .out {
    position: relative;
  }

b {
  font-style: normal;
  display: block;
  font-weight normal
  position: absolute;
  top: -15px;
  right: 0px;
  font-size 12px
}

em {
  font-style: normal;
  display: block;
  position: absolute;
  top: 8px;
  left: -10px;
  font-size 12px
}

.line {
  display: block;
  width: 160%;
  height: 1px;
  background: rgba(217, 217, 217, 1)
  transform: rotate(18deg);
  position: absolute;
  top: -3px;
  left: -46px;
}

.tip
  font-size: 14px;
  background: #F0F5FF;
  padding 16px 24px
  line-height 1.5
  margin-top 16px
</style>
