import {get} from "../axios";

const base = process.env.VUE_APP_API_ROOT;
const baseTaxfirm = base + '/taxfirm';

/**
 * 获取基本信息
 * @param uuid
 * @param desensitizationStatus
 * @returns {Promise<*>}
 */
export const getResultBasicInfo = async ({
                                           programId
                                         }) => {
  const url = `${baseTaxfirm}/businessData/basicInfo`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}
/**
 * 股东及管理人员信息
 * @param programId
 * @returns {Promise<*>}
 */
export const getExecutiveInfo = async ({
                                         programId
                                       }) => {
  const url = `${baseTaxfirm}/businessData/executiveInfo`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}
/**
 * 变更记录
 * @param programId
 * @returns {Promise<*>}
 */
export const getChangeRecord = async ({
                                        programId
                                      }) => {
  const url = `${baseTaxfirm}/businessData/changeRecord`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}
/**
 * 对外投资
 * @param programId
 * @returns {Promise<*>}
 */
export const getOutboundInvestment = async ({
                                              programId
                                            }) => {
  const url = `${baseTaxfirm}/businessData/outboundInvestment`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}
/**
 * 分支机构
 * @param programId
 * @returns {Promise<*>}
 */
export const getBranch = async ({
                                  programId
                                }) => {
  const url = `${baseTaxfirm}/businessData/branch`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}


/**
 * 风险预警
 * @param programId
 * @returns {Promise<*>}
 */
export const getRiskWarning = async ({
                                       programId
                                     }) => {
  const url = `${baseTaxfirm}/businessData/riskWarning`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}


/**
 * 正面公开信息
 * @param programId
 * @returns {Promise<*>}
 */
export const getPositiveInfo = async ({
                                        programId
                                      }) => {
  const url = `${baseTaxfirm}/businessData/positiveInfo`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}


/**
 * 上市公告
 * @param programId
 * @returns {Promise<*>}
 */
export const getListingAnnouncement = async ({
                                               programId
                                             }) => {
  const url = `${baseTaxfirm}/businessData/listingAnnouncement`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}

/**
 * 十大股东
 * @param programId
 * @returns {Promise<*>}
 */
export const getShareholderTopTen = async ({
                                             programId,
                                             time
                                           }) => {
  const url = `${baseTaxfirm}/businessData/shareholderTopTen`;
  const res = await get(url, {
    programId: programId,
    time
  });
  return res.data;
}
/**
 * 高管信息
 * @param programId
 * @returns {Promise<*>}
 */
export const getSeniorManagement = async ({
                                            programId
                                          }) => {
  const url = `${baseTaxfirm}/businessData/seniorManagement`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}

/**
 * 企业业务
 * @param programId
 * @returns {Promise<*>}
 */
export const getVocationalWork = async ({
                                          programId
                                        }) => {
  const url = `${baseTaxfirm}/businessData/vocationalWork`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}

/**
 * 融资历史
 * @param programId
 * @returns {Promise<*>}
 */
export const getFinancing = async ({
                                     programId
                                   }) => {
  const url = `${baseTaxfirm}/businessData/financing`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}

/**
 * 投资事件
 * @param programId
 * @returns {Promise<*>}
 */
export const getInvestEvent = async ({
                                       programId
                                     }) => {
  const url = `${baseTaxfirm}/businessData/invest`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}


/**
 * 行政许可
 * @param programId
 * @returns {Promise<*>}
 */
export const getAdministrativeLicense = async ({
                                                 programId
                                               }) => {
  const url = `${baseTaxfirm}/businessData/administrativeLicense`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}

/**
 * 进出口信息
 * @param programId
 * @returns {Promise<*>}
 */
export const getImportsAndExports = async ({
                                             programId
                                           }) => {
  const url = `${baseTaxfirm}/businessData/importsAndExports`;
  const res = await get(url, {
    programId: programId
  });
  return res.data;
}
