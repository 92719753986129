<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">4.5 进出口信用({{ total }})</div>
      <div class="xtable">
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide">
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '操作'">
              <button class="btn-link" @click="handleViewClick(index)">查看</button>
            </template>
          </template>
        </EasyTable>
        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else>
          <template v-slot:customColumns="{ column, record, cell, index }">
            <template v-if="column.key === '操作'">
              <button class="btn-link" @click="handleViewClick(index)">查看</button>
            </template>
          </template>
        </EasyTable2>
      </div>
    </div>
    <ImportsAndExportsDialog ref="importsAndExportsDialog"></ImportsAndExportsDialog>
  </div>
</template>

<script>
import {
  getAdministrativeLicense,
  getFinancing,
  getImportsAndExports,
  getInvestEvent,
  getVocationalWork,
} from "@/api/gongshangquanjing";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

import { cloneDeep } from "lodash";
import ImportsAndExportsDialog from "@/pages/Result/GongshangquanjingReport/importsAndExports/ImportsAndExportsDialog.vue";

export default {
  name: "ImportsAndExports",
  props: {
    hide: Boolean,
  },
  components: {
    ImportsAndExportsDialog,
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getImportsAndExports({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      const header = {};

      this.data.header.forEach((name, index) => {
        let width = null;
        if (index == 0) {
          width = 70;
        }
        if (index == 1) {
          width = 200;
        }
        if (index == this.data.header.length - 1) {
          width = null;
        }
        header[name] = {
          value: name,
          key: name,
          resizable: true,
          minWidth: 100,
          width,
          editorType: "text",
        };
      });
      header["操作"] = {
        value: "操作",
        key: "operation",
        resizable: true,
        minWidth: 100,
        width: null,
        editorType: "text",
        custom: true,
        fixed: "right",
      };
      const data = cloneDeep(this.data);

      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    handleViewClick(index) {
      this.$refs["importsAndExportsDialog"].open(this.data.subTables[index]);
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
}
</style>
