<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import echarts from "echarts";
import {toFixed} from "@/utils/format";

export default {
  mounted() {
    this.showMap();
  },
  props: {
    colors: Array,
    data: Array,
    name: String
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "300px"; //window.innerWidth * 0.5 + "px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"], null, {
        devicePixelRatio: 4,
        renderer: 'svg'
      });
      const series = this.data.map((v, index) => {
        return {
          barWidth: 200,//柱图宽度
          name: v.name,
          type: 'bar',
          color: this.colors[index % this.colors.length],
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series'
          },
          data: [v.value, index]
        }
      });
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
          left: '-32%',
          right: '0%',
          bottom: 0,
          top: '0%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'category',
          data: [this.name + '(万元)']
        },
        yAxis: {
          show: false,
          type: 'value',
          max: () => {
            const max = this.data.reduce((acc, cur) => {
              return acc + parseFloat(cur.value)
            }, 0)
            return max;
          }
        },
        series,
      };

      this.chartColumn.setOption(option);
      // this.chartColumn.on("mouseover", (param) => {
      //   console.log("param", param)
      // })
    }
  }
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 300px;
  height: 300px;
}
</style>
