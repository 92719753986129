import {isDesensitizationUuid} from "@/utils/helper";
import {guessTdStyle, guessThStyle} from "@/utils/table";

let mixin = {
  methods: {
    guessThStyle(label, index) {
      return guessThStyle(label, index)
    },
    guessTdStyle(value, index,label) {
      return guessTdStyle(value, index,label)
    },
  }
};

export default mixin;
