<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">4.2 融资历史({{ total }})</div>
      <div class="xtable">
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide"> </EasyTable>
        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else> </EasyTable2>
      </div>
    </div>
  </div>
</template>

<script>
import { getFinancing, getVocationalWork } from "@/api/gongshangquanjing";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

import { cloneDeep } from "lodash";

export default {
  name: "FinancingHistory",
  props: {
    hide: Boolean,
  },
  components: {
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getFinancing({ programId: this.$route.params.programId });
    this.data = res.data.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      const header = {};

      this.data.header.forEach((name, index) => {
        let width = null;
        if (index == 0) {
          width = 70;
        }
        // if (index == 1) {
        //   width = 200;
        // }
        if (index == this.data.header.length - 1) {
          width = null;
        }
        header[name] = {
          value: name,
          key: name,
          resizable: true,
          minWidth: 100,
          width,
          editorType: "text",
        };
      });
      const data = cloneDeep(this.data);
      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
}
</style>
