var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ more: _vm.mode === 'more' }},[(_vm.mode === 'more' && _vm.isMoreThanStandard)?_c('div',{staticClass:"float-table"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.labels),function(label,index){return _c('th',{key:index,style:(_vm.guessThStyle(label, index))},[_vm._v(" "+_vm._s(label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:index},_vm._l((row),function(col,index2){return _c('td',{key:index2,style:({ ..._vm.guessTdStyle(col, index2, _vm.labels[index2]) })},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.tipText(index, index2),"placement":"top-start","disabled":!_vm.tipText(index, index2)}},[(col == 'button{detail}')?_c('span',{staticClass:"clickable",class:{ 'text-has-tip': _vm.tipText(index, index2) },on:{"click":function($event){return _vm.handleCellClick(index)}}},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleToggleDetailCLick.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.toggleLabel))])]):_c('span',{class:{
                  'text-has-tip': _vm.tipText(index, index2),
                  'text-has-table': !!_vm.subTable(index, index2),
                },domProps:{"innerHTML":_vm._s(col)},on:{"click":function($event){_vm.handleSubTableLinkClick(_vm.subTable(index, index2), index, index2)}}})])],1)}),0)}),0)])]):_vm._e(),_c('div',{ref:"scroller",staticClass:"scroller"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.labels),function(label,index){return _c('th',{key:index,style:(_vm.guessThStyle(label, index))},[_vm._v(" "+_vm._s(label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:index},_vm._l((row),function(col,index2){return _c('td',{key:index2,style:({ ..._vm.guessTdStyle(col, index2, _vm.labels[index2]) })},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.tipText(index, index2),"placement":"top-start","disabled":!_vm.tipText(index, index2)}},[(col == 'button{detail}')?_c('span',{staticClass:"clickable",class:{ 'text-has-tip': _vm.tipText(index, index2) },on:{"click":function($event){return _vm.handleCellClick(index)}}},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleToggleDetailCLick.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.toggleLabel))])]):_c('span',{class:{
                  'text-has-tip': _vm.tipText(index, index2),
                  'text-has-table': !!_vm.subTable(index, index2),
                },domProps:{"innerHTML":_vm._s(col)},on:{"click":function($event){_vm.handleSubTableLinkClick(_vm.subTable(index, index2), index, index2)}}})])],1)}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }