<template>
  <div>
    <!--    <JindiaoTable-->
    <!--      v-if="generalTableData"-->
    <!--      :data="generalTableData"-->
    <!--      mode="more"-->
    <!--    ></JindiaoTable>-->
    <div class="xtable">
      <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide"> </EasyTable>
      <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else> </EasyTable2>
    </div>
    <!--    <template v-if="dataBodyLength>5">-->
    <!--      <button class="btn btn-default btn-more" v-if="brief" @click="brief=false">-->
    <!--        <i class="el-icon-arrow-down"></i> 内容展开-->
    <!--      </button>-->
    <!--      <button class="btn btn-default btn-more" v-else @click="brief=true">-->
    <!--        <i class="el-icon-arrow-up"></i> 内容收起-->
    <!--      </button>-->
    <!--    </template>-->
  </div>
</template>

<script>
import JindiaoTable from "@/pages/Result/GongshangquanjingReport/components/JindiaoGenericTable";
import { cloneDeep } from "lodash";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

export default {
  name: "MoreTable",
  components: {
    EasyTable,
    EasyTable2,
  },
  mounted() {},
  data() {
    return {
      brief: true,
    };
  },
  props: {
    data: Object,
    hide: Boolean,
  },
  computed: {
    entityJson() {
      const header = {};

      this.data.header.forEach((name, index) => {
        let width = null;
        if (index == 0) {
          width = 70;
        }
        // if (index == 1) {
        //   width = 200;
        // }
        if (index == this.data.header.length - 1) {
          width = null;
        }
        header[name] = {
          value: name,
          key: name,
          resizable: true,
          minWidth: 100,
          width,
          editorType: "text",
        };
      });
      const data = cloneDeep(this.data);
      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  margin-bottom: 16px;
  //height 400px
}

.btn.btn-more
  margin 16px auto
  display block
  width: 400px;
  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #061178;
  color: #061178 !important;

.btn.btn-more:hover {
  color #fff !important;
  background #2f54eb !important;
}
</style>
