var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"easy-table-wrapper"},[_c('div',{staticClass:"table-wrapper"},[_c('vxe-table',{ref:"xTable",staticClass:"x-table",attrs:{"header-cell-class-name":"headerCellClassName","cell-class-name":"cellClassName","scroll-y.mode":"wheel","scroll-x.mode":"wheel","show-footer":_vm.isShowFooter,"border":"","loading":_vm.loading,"keep-source":"","resizable":true,"column-config":{ resizable: true },"scroll-x":{ gt: 100 },"scroll-y":{ gt: 100 },"show-header-overflow":"","show-overflow":"","height":"100%","width":"100%"},on:{"filter-change":_vm.handleFilterChange}},[_vm._l((_vm.theData.tableColumn),function(column,index){return [_c('vxe-column',{key:index,attrs:{"title":column.value,"fixed":column.fixed,"width":column.width,"visible":!column.hide,"align":column.textAlign,"min-width":column.minWidth,"max-width":column.maxWidth,"field":column['key'] + '.value',"edit-render":{ enabled: true },"filter-multiple":false,"filters":column.filters,"filter-method":column.filterMethod},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(column.custom)?[_vm._t("customColumns",null,{"column":column,"record":scope.row,"cell":scope.row[column['key']],"index":scope.rowIndex})]:[_vm._v(" "+_vm._s(scope.row[column["key"]]?.value)+" ")]]}}],null,true)})]})],2)],1),_c('vxe-pager',{directives:[{name:"show",rawName:"v-show",value:(this.hide),expression:"this.hide"}],attrs:{"perfect":"","current-page":_vm.pagination.currentPage,"page-size":_vm.pagination.pageSize,"total":_vm.pagination.totalResult,"page-sizes":[
        10,
        20,
        100,
        { label: '大量数据', value: 1000 },
        { label: '全量数据', value: -1 },
      ],"layouts":['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"page-change":_vm.handlePageChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }