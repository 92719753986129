<template>
  <div>
    <div class="section-wrapper" v-if="loaded">
      <div class="section-title">2.2 十大股东({{ total }})</div>
      <div class="xtable">
        <div class="toolbar">
          <el-select
            v-model="time"
            placeholder="请选择时间"
            @change="handleTimeSelectChanged"
            clearable
            v-show="this.hide"
          >
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <EasyTable ref="easyTable" :data="entityJson" :hide="hide" v-if="this.hide"> </EasyTable>
        <EasyTable2 ref="easyTable" :data="entityJson" :hide="hide" v-else> </EasyTable2>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getShareholderTopTen } from "@/api/gongshangquanjing";
import EasyTable from "@/pages/Result/components/EasyTable.vue";
import EasyTable2 from "@/pages/Result/components/EasyTable2.vue";

export default {
  name: "TopTenShareHolders",
  props: {
    hide: Boolean,
  },
  components: {
    EasyTable,
    EasyTable2,
  },
  data() {
    return {
      loaded: false,
      input: "",
      data: null,
      time: "",
      timeOptions: [],
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    entityJson() {
      const header = {
        序号: {
          value: "序号",
          key: "序号",
          resizable: true,
          minWidth: 100,
          width: 70,
          editorType: "text",
          textAlign: "center",
        },
        机构或基金: {
          value: "机构或基金",
          key: "机构或基金",
          resizable: true,
          // width: 190,
          editorType: "text",
        },
        持有数量: {
          value: "持有数量",
          key: "持有数量",
          resizable: true,
          editorType: "text",
        },
        "持股变化（股）": {
          value: "持股变化（股）",
          key: "持股变化（股）",
          resizable: true,
          editorType: "text",
        },
        "占股本比例（100%）": {
          value: "占股本比例（100%）",
          key: "占股本比例（100%）",
          resizable: true,
          editorType: "text",
        },
        "实际增减持（100%）": {
          value: "实际增减持（%）",
          key: "实际增减持（%）",
          resizable: true,
          editorType: "text",
        },
        股份类型: {
          value: "股份类型",
          key: "股份类型",
          resizable: true,
          editorType: "text",
        },
      };

      const data = cloneDeep(this.data);
      data.header = header;
      return data || {};
    },
    total() {
      return (this.data && this.data.body && this.data.body.length) || 0;
    },
  },
  methods: {
    async load() {
      this.loaded = false;
      const res = await getShareholderTopTen({
        programId: this.$route.params.programId,
        time: this.time,
      });
      this.data = res.data.items;
      this.timeOptions = res.data.timeList.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      this.loaded = true;
    },
    handleInputSearchClick() {
      this.$refs["easyTable"].loadTableData();
    },

    handleTimeSelectChanged() {
      this.load();
    },
  },
};
</script>

<style lang="stylus" scoped>
.xtable {
  //margin-top: 16px;
}
  .toolbar{
    display flex
    justify-content flex-end
    margin-bottom 10px
    margin-top 10px;
  }
</style>
