import { render, staticRenderFns } from "./ChangeLogMoreTable.vue?vue&type=template&id=7c11f630&scoped=true&"
import script from "./ChangeLogMoreTable.vue?vue&type=script&lang=js&"
export * from "./ChangeLogMoreTable.vue?vue&type=script&lang=js&"
import style0 from "./ChangeLogMoreTable.vue?vue&type=style&index=0&id=7c11f630&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c11f630",
  null
  
)

export default component.exports