<template>
  <div>
    <div class="easy-table-wrapper">
      <div class="table-wrapper">
        <vxe-table
          class="x-table"
          style="height: 400px"
          header-cell-class-name="headerCellClassName"
          cell-class-name="cellClassName"
          scroll-y.mode="wheel"
          scroll-x.mode="wheel"
          :show-footer="isShowFooter"
          border
          :loading="loading"
          keep-source
          :resizable="true"
          :column-config="{ resizable: true }"
          :scroll-x="{ gt: 100 }"
          :scroll-y="{ gt: 100 }"
          ref="xTable"
          show-header-overflow
          show-overflow
          height="100%"
          width="100%"
          @filter-change="handleFilterChange"
        >
          <template v-for="(column, index) in theData.tableColumn">
            <vxe-column
              :title="column.value"
              :fixed="column.fixed"
              :width="column.width"
              :visible="!column.hide"
              :align="column.textAlign"
              :min-width="column.minWidth"
              :max-width="column.maxWidth"
              :field="column['key'] + '.value'"
              :edit-render="{ enabled: true }"
              :key="index"
              :filter-multiple="false"
              :filters="column.filters"
              :filter-method="column.filterMethod"
            >
              <template v-slot="scope">
                <template v-if="column.custom">
                  <slot
                    name="customColumns"
                    :column="column"
                    :record="scope.row"
                    :cell="scope.row[column['key']]"
                    :index="scope.rowIndex"
                  ></slot>
                </template>
                <template v-else>
                  {{ scope.row[column["key"]]?.value }}
                </template>
              </template>
            </vxe-column>
          </template>
        </vxe-table>
      </div>
      <vxe-pager
        v-show="this.hide"
        perfect
        :current-page.sync="pagination.currentPage"
        :page-size.sync="pagination.pageSize"
        :total="pagination.totalResult"
        @page-change="handlePageChange"
        :page-sizes="[
          10,
          20,
          100,
          { label: '大量数据', value: 1000 },
          { label: '全量数据', value: -1 },
        ]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
      >
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, defineProps, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";

export default {
  name: "EasyTable",
  props: {
    data: Object,
    isShowFooter: {
      type: Boolean,
      default: false,
    },
    hide: Boolean,
  },

  setup(props) {
    const loading = ref(false);
    const uuid = uuidv4();
    const isShowFooter = ref(false); //是否显示表格底部
    const pagination = reactive({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });
    const theData = reactive({
      tableColumn: [],
      tableData: [],
      // tableData: [
      //   {id: 10001, name: 'Test1', nickname: 'T1', role: 'Develop', sex: '1', age: 28, address: 'test abc'},
      //   {id: 10002, name: 'Test2', nickname: 'T2', role: 'Test', sex: '0', age: 22, address: 'Guangzhou'},
      //   {id: 10003, name: 'Test3', nickname: 'T3', role: 'PM', sex: '1', age: 32, address: 'Shanghai'},
      //   {id: 10004, name: 'Test4', nickname: 'T4', role: 'Designer', sex: '0 ', age: 23, address: 'test abc'},
      //   {id: 10005, name: 'Test5', nickname: 'T5', role: 'Develop', sex: '0 ', age: 30, address: 'Shanghai'},
      //   {id: 10006, name: 'Test6', nickname: 'T6', role: 'Designer', sex: '0 ', age: 21, address: 'test abc'},
      //   {id: 10007, name: 'Test7', nickname: 'T7', role: 'Test', sex: '1', age: 29, address: 'test abc'},
      //   {id: 10008, name: 'Test8', nickname: 'T8', role: 'Develop', sex: '1', age: 35, address: 'test abc'}
      // ]
    });

    const xTable = ref();

    /**
     * 处理分页改变
     */
    function handlePageChange({ type, currentPage, pageSize, $event }) {
      pagination.currentPage = currentPage;
      pagination.pageSize = pageSize;
      loadData();
    }

    function headerCellClassName({ column, columnIndex }) {
      if (column.property === "name") {
        return "col-blue";
      }
    }

    function cellClassName({ column, columnIndex }) {
      if (column.property === "name") {
        return "col-blue";
      }
    }

    const debouncedLoadData = debounce(loadData, 500);

    /**
     * 设置表头
     * @param header
     */
    function setTableHeader(header) {
      const headWithMeta = Object.entries(header)
        .map((value) => {
          const ret = {
            value: value[1].value,
            key: value[0],
            resizable: true,
            minWidth: 100,
            textAlign: value[1].textAlign || "left",
            width: value[1].width,
            maxWidth: value[1].maxWidth || 500,
            editorType: value[1].editorType,
            default: value[1].default,
            calc: value[1].calc,
            change: value[1].change,
            click: value[1].click,
            custom: value[1].custom,
            fixed: value[1].fixed,
            hide: value[1].hide,
            filters: value[1].filter && value[1].filter.options,
            filterMethod: ({ theValue, row, column }) => {
              return true;
            },
            filterHandler: value[1].filter?.handler,
          };

          if (value[1].children) {
            ret.children = Object.entries(value[1].children).map((value) => {
              return {
                value: value[1].value,
                key: value[0],
                resizable: true,
                minWidth: value[1].minWidth || 100,
                width: value[1].width,
                maxWidth: value[1].maxWidth || 500,
                editorType: value[1].editorType,
                default: value[1].default,
                calc: value[1].calc,
                change: value[1].change,
                click: value[1].click,
                custom: value[1].custom,
                fixed: value[1].fixed,
                hide: value[1].hide,
              };
            });
          }
          return ret;
        })
        .filter((value) => value.hide !== true);
      theData.tableColumn = headWithMeta;
    }

    let theFilterList = [];

    function handleFilterChange({ column, property, values, datas, filterList, $event }) {
      theFilterList = filterList;
      pagination.currentPage = 1;
      loadData();
    }

    /**
     * 把row转换成表格的TableBodyRow格式
     * @param row
     * @param index
     */
    function convertRowToTableRow(row, index) {
      const ret = {};
      Object.entries(row).map(([key, value]) => {
        let options = undefined;
        const headerItem = theData.tableColumn?.find((record) => {
          return record.key === key;
        });
        if (headerItem) {
          if (headerItem.default != undefined && value == null) {
            value = headerItem.default;
          }
          if (headerItem.editorType?.type === "date") {
            if (value) {
              value = dayjs(value, dateFormat).format("YYYY-MM-DD");
            } else {
              value = null;
            }
          }
          //如果不是临时数据，则执行自动计算
          // if (index != null && headerItem.calc != undefined) {
          //   emitter.on(`editableTableChanged_${uuid}`, (theDataSource) => {
          //     const currentRow = theDataSource[index];
          //     headerItem.calc(currentRow);
          //   });
          // }

          if (headerItem.editorType?.type === "select") {
            options = headerItem.editorType.options;
          }
        }

        ret[key] = {
          value,
          options,
        };
      });
      return ret;
    }

    async function loadData() {
      //根据pagination.currentPage和pagination.pageSize对theData.tableData进行分页
      let pagedData = theData.tableData.filter((row, index) => {
        const isShow = Object.entries(row).every(([key, column]) => {
          const headerItem = theData.tableColumn?.find((record) => {
            return record.key === key;
          });

          if (headerItem?.filterHandler) {
            const theFilters = theFilterList.find((filter) => {
              return filter.property === `${key}.value`;
            });
            if (theFilters && theFilters.values.length > 0) {
              return headerItem?.filterHandler({
                value: column.value,
                row,
                headerItem,
                filters: theFilters.values,
              });
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
        return isShow;
      });

      pagination.totalResult = pagedData.length;

      pagedData = pagedData.filter((row, index) => {
        const ret =
          (pagination.currentPage - 1) * pagination.pageSize <= index &&
          index < pagination.currentPage * pagination.pageSize;
        return ret;
      });

      await xTable.value.loadData(pagedData);
    }

    /**
     * 设置表体
     * @param data
     */
    async function setTableBody(data) {
      loading.value = true;
      const processedTable = data.map((row, index) => {
        const tableRow = convertRowToTableRow(row, index);
        return tableRow;
      });
      theData.tableData = processedTable;

      theData.tableData.map((row, index) => {
        const ret = {};
        Object.entries(row).map(([key, value]) => {
          const headerItem = theData.tableColumn?.find((record) => {
            return record.key === key;
          });
          if ((headerItem && headerItem.change)?.immediate) {
            (headerItem?.change).handler(row);
          }
        });
      });

      await loadData();

      loading.value = false;
    }

    onMounted(() => {
      loadTableData();
    });

    function loadTableData() {
      setTableHeader(props.data.header);
      setTableBody(props.data.body);
      pagination.currentPage = 1;
    }

    return {
      loadTableData,
      loading,
      theData,
      xTable,
      hide: props.hide,
      isShowFooter: props.isShowFooter,
      headerCellClassName,
      cellClassName,
      pagination,
      handlePageChange,
      handleFilterChange,
    };
  },
};
</script>

<style lang="stylus" scoped>
.easy-table-wrapper {
  height 100%
  width 100%

  .table-wrapper {
    width 100%
    height calc(100% - 48px)

    min-height 200px

    .x-table {
      height 100%
    }
  }
}


:deep(.headerCellClassName) {
  line-height: 1.5;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  font-weight: 400;
  font-size: 14px;
  background #f0f5ff !important;
}

:deep(.cellClassName) {
  line-height: 1.5;
  background #fbfbfb
  font-weight: 400;
  font-size: 14px;
}
</style>
