<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="section-title">一. 风险预警</div>

    <el-tabs v-model="activeNameEvery" type="card" @tab-click="handleClick" v-if="!this.hide">
      <el-tab-pane label="全部显示" :name="'-1'" :key="-1">
        <template v-if="-1 == activeNameEvery">
          <div class="table-box" v-for="(data, key) in entityJson" :key="key">
            <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
            <template v-if="Array.isArray(data.data)">
              <MoreTable
                :data="data2.data"
                v-for="(data2, key2) in data.data"
                :key="key2"
                :hide="hide"
              ></MoreTable>
              <!--  -->
            </template>
            <template v-else>
              <MoreTable :data="data.data" :hide="hide"></MoreTable>
            </template>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" v-else>
      <el-tab-pane
        :label="`${data.name}(${data.total})`"
        :name="key + ''"
        v-for="(data, key) in entityJson"
        :key="key"
      >
        <div class="table-box" v-if="key + '' == activeName">
          <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
          <template v-if="Array.isArray(data.data)">
            <MoreTable
              :data="data2.data"
              v-for="(data2, key2) in data.data"
              :key="key2"
              :hide="hide"
            ></MoreTable>
          </template>
          <template v-else>
            <MoreTable :data="data.data" :hide="hide"></MoreTable>
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部显示" :name="'-1'" :key="-1">
        <template v-if="-1 == activeName">
          <div class="table-box" v-for="(data, key) in entityJson" :key="key">
            <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
            <template v-if="Array.isArray(data.data)">
              <MoreTable
                :data="data2.data"
                v-for="(data2, key2) in data.data"
                :key="key2"
                :hide="hide"
              ></MoreTable>
            </template>
            <template v-else>
              <MoreTable :data="data.data" :hide="hide"></MoreTable>
            </template>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MoreTable from "@/pages/Result/GongshangquanjingReport/components/MoreTable";

import { getDueDiligenceModel003 } from "@/api/api";
import { getDesensitizationFromRouter } from "@/utils/helper";
import { getRiskWarning } from "@/api/gongshangquanjing";

export default {
  name: "PublicPositiveInformation",
  props: {
    hide: Boolean,
  },
  components: {
    MoreTable,
  },
  data() {
    return {
      activeName: "0",
      activeNameEvery: "-1",

      data: null,
      loaded: false,
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getRiskWarning({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      return this.data ? this.data : {};
    },
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
  },
};
</script>

<style lang="stylus" scoped></style>
