<template>
  <el-dialog
    title="主要人员详情"
    :visible.sync="dialogVisible"
    width="1200px"
  >
    <div
      v-loading="!loaded">
      <table class="table">
        <tbody>
        <tr>
          <td width="186px">姓名</td>
          <td width="248px">{{ data.name }}</td>
          <td width="186px">职务</td>
          <td>{{ data.position }}</td>
        </tr>
        <tr>
          <td>性别</td>
          <td>{{ data.sex }}</td>
          <td>年龄</td>
          <td>{{ data.age }}</td>
        </tr>
        <tr>
          <td>学历</td>
          <td>{{ data.education }}</td>
          <td>薪酬</td>
          <td>{{ data.salary }}</td>
        </tr>
        <tr>
          <td>持股数</td>
          <td>{{ data.numberOfShares }}</td>
          <td>公告日期</td>
          <td>{{ data.reportDate }}</td>
        </tr>
        <tr>
          <td>本届任期</td>
          <td colspan="3">{{ data.term }}</td>
        </tr>
        <tr>
          <td>简介</td>
          <td colspan="3">
            {{ data.resume }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import BigPowerTable from "big-power-table"
import {getCompanyRiskInfo} from "@/api/threeYears";

export default {
  name: "SeniorManagementDialog",
  data() {
    return {
      dialogVisible: false,
      data: {},
      loaded: false,
    };
  },
  methods: {
    async open(data) {
      this.data = data.detailedTable
      this.loaded = false
      this.dialogVisible = true;
      this.loaded = true
    },

  }
}
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header
  padding 24px 20px 16px
  border-bottom 0 none;

>>> .el-dialog__body
  padding 0px 20px 20px 20px

.table {
  border-collapse: collapse;
  box-shadow: 0px -1px 0px 0px #EEEEEE;
  background #fff;
  width 100%
  border-right 1px solid #EEEEEE;

  td, th {
    padding 10px
    text-align left
    border-right 1px solid #D9D9D9;
    border-bottom 1px solid #EEEEEE;
    border-left 1px solid #EEEEEE;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }

  td:last-child, th:last-child {
    border-right none;
  }

  thead {
    background: #F0F5FF;
  }

  th {
    color: rgba(0, 0, 0, 0.8);
    font-weight bold
  }


}

.table td:nth-child(odd) {
  background-color: #f0f7fc;
}
</style>
