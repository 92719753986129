<template>
  <div class="section-wrapper" v-if="loaded">
    <div class="titles">提示:本部分各项内容按时间顺序展示前200条数据。</div>
    <!--    <div class="section-title">-->
    <!--      二. {{ entityJson.name }}-->
    <!--    </div>-->

    <!-- 打印PDF全部展示 -->
    <el-tabs v-model="activeNameEvery" type="card" @tab-click="handleClick" v-if="!this.hide">
      <el-tab-pane label="全部显示" :name="'-1'" :key="-1">
        <template>
          <div class="table-box" v-for="(data, key) in entityJson" :key="key">
            <div class="tableTop" v-if="!Array.isArray(data.data)">
              {{ data.name }}（{{ data.total }}）
            </div>
            <template v-if="Array.isArray(data.data)">
              <div v-for="(data2, key2) in data.data" :key="key2">
                <div class="tableTop">{{ data2.name }}（{{ data2.total }}）</div>
                <MoreTable :data="data2.data" :hide="hide"></MoreTable>
              </div>
            </template>
            <template v-else>
              <MoreTable :data="data.data" :hide="hide"></MoreTable>
            </template>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" v-else>
      <el-tab-pane
        :label="`${data.name}(${data.total})`"
        :name="key + ''"
        v-for="(data, key) in entityJson"
        :key="key"
      >
        <div class="table-box" v-if="key + '' == activeName">
          <template v-if="Array.isArray(data.data)">
            <div v-for="(data2, key2) in data.data" :key="key2">
              <div class="tableTop">{{ data2.name }}（{{ data2.total }}）</div>
              <MoreTable :data="data2.data" :hide="hide"></MoreTable>
            </div>
          </template>
          <template v-else>
            <div class="tableTop">{{ data.name }}（{{ data.total }}）</div>
            <MoreTable :data="data.data" :hide="hide"></MoreTable>
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部显示" :name="'-1'" :key="-1">
        <template v-if="-1 == activeName">
          <div class="table-box" v-for="(data, key) in entityJson" :key="key">
            <div class="tableTop" v-if="!Array.isArray(data.data)">
              {{ data.name }}（{{ data.total }}）
            </div>
            <template v-if="Array.isArray(data.data)">
              <div v-for="(data2, key2) in data.data" :key="key2">
                <div class="tableTop">{{ data2.name }}（{{ data2.total }}）</div>
                <MoreTable :data="data2.data" :hide="hide"></MoreTable>
              </div>
            </template>
            <template v-else>
              <MoreTable :data="data.data" :hide="hide"></MoreTable>
            </template>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MoreTable from "@/pages/Result/GongshangquanjingReport/components/MoreTable";
import { getDueDiligenceModel002 } from "@/api/api";
import OriginalTable from "@/pages/Result/GongshangquanjingReport/components/OriginalTable";
import { getDesensitizationFromRouter } from "@/utils/helper";
import { getPositiveInfo } from "@/api/gongshangquanjing";

export default {
  name: "PublicPositiveInformation",
  props: {
    hide: Boolean,
  },
  components: {
    MoreTable,
  },
  data() {
    return {
      // activeName: "-1",
      activeName: "0",
      data: null,
      loaded: false,
      activeNameEvery: "-1",
    };
  },
  async mounted() {
    this.loaded = false;
    const res = await getPositiveInfo({ programId: this.$route.params.programId });
    this.data = res.data;
    this.loaded = true;
  },
  computed: {
    entityJson() {
      return this.data ? this.data : {};
    },
  },
  methods: {
    handleClick(tab, event) {},
  },
};
</script>

<style lang="stylus" scoped>
.section-wrapper{
  position relative;
}
.titles {
  position: absolute;
  top: 35px;
  right:20px;
  color: gray
  font-size: 12px;
}
</style>
