import { render, staticRenderFns } from "./BasisSection.vue?vue&type=template&id=4b6be37d&scoped=true&"
import script from "./BasisSection.vue?vue&type=script&lang=js&"
export * from "./BasisSection.vue?vue&type=script&lang=js&"
import style0 from "./BasisSection.vue?vue&type=style&index=0&id=4b6be37d&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6be37d",
  null
  
)

export default component.exports