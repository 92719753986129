<template>
  <div class="left" ref="attrRef">
    <el-menu
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      :default-openeds="openeds"
      @select="handleSelect"
    >
      <el-submenu index="1" class="ioc">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span slot="title">1 企业背景</span>
        </template>

        <a href="#BasisSection">
          <el-menu-item index="1-1">1.1基本信息</el-menu-item>
        </a>
        <a href="#ShareHolderAndManagementInfo">
          <el-menu-item index="1-2">1.2股东及管理人员信息</el-menu-item>
        </a>
        <a href="#ChangeLog">
          <el-menu-item index="1-3">1.3变更记录</el-menu-item>
        </a>
        <a href="#OutboundInvestment">
          <el-menu-item index="1-4">1.4对外投资</el-menu-item>
        </a>
        <a href="#Branch">
          <el-menu-item index="1-5">1.5分支机构</el-menu-item>
        </a>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span slot="title">2 上市公司信息</span>
        </template>
        <a href="#ListedCompanyAnnouncement">
          <el-menu-item index="2-1"> 2.1上市公告</el-menu-item>
        </a>
        <a href="#TopTenShareHolders">
          <el-menu-item index="2-2"> 2.2十大股东</el-menu-item>
        </a>
        <a href="#SeniorManagement">
          <el-menu-item index="2-3">2.3高管信息</el-menu-item>
        </a>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span slot="title">3 风险预警</span>
        </template>
        <a href="#RiskWarning">
          <el-menu-item index="3-1">3.1经营异常</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-2">3.2行政处罚</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-3">3.3严重违法</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-4">3.4税收违法</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-5">3.5欠税公告</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-6">3.6被执行人</el-menu-item>
        </a>
        <a href="#RiskWarning">
          <el-menu-item index="3-7">3.7失信人</el-menu-item>
        </a>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span slot="title">4 公司发展</span>
        </template>
        <a href="#CompanyBusiness">
          <el-menu-item index="4-1"> 4.1企业业务</el-menu-item>
        </a>
        <a href="#FinancingHistory">
          <el-menu-item index="4-2"> 4.2融资历史</el-menu-item>
        </a>
        <a href="#InvestHistory">
          <el-menu-item index="4-3">4.3投资事件</el-menu-item>
        </a>
        <a href="#AdministrativeLicense">
          <el-menu-item index="4-4">4.4行政许可</el-menu-item>
        </a>
        <a href="#ImportsAndExports">
          <el-menu-item index="4-5">4.5进出口信用</el-menu-item>
        </a>
      </el-submenu>

      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span slot="title">5 公开正面信息</span>
        </template>
        <a href="#PublicPositiveInformation">
          <el-menu-item index="5-1"> 5.1企业资质</el-menu-item>
        </a>
        <a href="#PublicPositiveInformation">
          <el-menu-item index="5-2"> 5.2知识产权</el-menu-item>
        </a>
        <a href="#PublicPositiveInformation">
          <el-menu-item index="5-3"> 5.3招投标</el-menu-item>
        </a>
      </el-submenu>
    </el-menu>
    <div class="open">
      <button @click="isCollapse = true" v-if="!isCollapse" class="openBtn">
        <!-- <i class="iconfont iconxiangzuo" style="color: #165dff"></i> -->
        <i class="iconfont iconxiangzuo" style="color: #dedede"></i>
      </button>

      <button @click="isCollapse = false" v-else class="openBtn" style="margin-left: 18px">
        <i class="iconfont iconxiangyou" style="margin-left: 18px; color: #dedede"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "jinZhiReportMenu",

  data() {
    return {
      isCollapse: false,
      openeds: ["1", "2", "3", "4", "5"],
      key: "",
      menuVoList: [
        {
          menu: {
            name: "系统用户权限管理",
            index: "0-1",
          },
        },
        {
          menu: {
            name: "系统用户权限管理",
            index: "0-1",
          },
        },
      ],
    };
  },
  methods: {
    //侧边栏展开收起
    handleOpen(key, keyPath) {},
    handleClose(done) {},
    handleSelect(key, keyPath) {
      console.log(key);
    },
  },
  async mounted() {},
};
</script>
<style lang="stylus" scoped>
.el-menu-vertical-demo {
  overflow-y auto
  transition all
}

.open {
  border: none;
  margin-top: 205px;
  margin-left: -18px;
  margin-right: 8px;
  z-index:1;
  line-height 28px
  display: flex;
  flex-direction: column
  border-radius: 50%;
  button{
    border:none;
    //background: rgba(255,255,255,0.01);
    border-radius: 50%;
  }
  .iconfont{
    font-size: 32px;
    border-radius: 50%;
  }
}
.openBtn{
  transition: transform 0.6s;
  transform: rotate(180deg)
  background:#fff;
  padding:0;
}
.open:hover .iconfont{
  border-radius: 50%;
  font-size: 32px;
  //text-shadow: 0px 0px 3px rgba(0,0,0,.2);

//-webkit-box-shadow: 3px 3px 3px #000;
box-shadow:0px 0px 14px #d5d0d0f2;;
}


.open >>> label .el-radio-button__inner {
  padding: 1px 8px;
}
.divs{
  position relative;
  overflow: auto;

  >.open{
    //height: 100%;
    position: absolute;
    right: -15px;
    top:0;
    bottom: 0;
  }
}
.left {
  display: flex;
  justify-content: space-between
  position fixed
  overflow: auto;
  top: 0;
  left: 0px;
  bottom: 0;


  .el-menu--collapse {
    width: 1px;

    .el-submenu {
      display: none;
    }
  }
}



.el-radio-button__inner {
  padding 6px 13px
}

.el-menu-vertical-demo >>> .el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
}

.ioc .el-submenu__title .el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
  top: 2px
}

.el-submenu__icon-arrow el-icon-arrow-down {
  margin-top: 83%;
  top: 2px
}


/* 设置垂直滚动条的宽度和水平滚动条的高度 */
::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

/* 设置滚动条的滑轨 */
::-webkit-scrollbar-track {
	background-color: #fff;
}

/* 滑块 */
::-webkit-scrollbar-thumb{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width:2px;
}
a{
  text-decoration: none;
}
</style>
