<template>
  <div class="table">
    <table>
      <tbody>
      <tr>
        <th>序号</th>
        <th colspan="2">{{data.sq}}
        </th>
        <th colspan="2">{{data.bq}}</th>
      </tr>
      <tr  v-for="(row,index) in data.bodyList" :key="index">
        <td :style="guessTdStyle(col,index2)" v-for="(col,index2) in row" :key="index2">{{col}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableMix from "@/mix/TableMix";

export default {
  name: "PurchaseTable",
  props:{
    data:Object
  },
  mixins:[
    TableMix
  ]
}
</script>

<style scoped>

</style>
