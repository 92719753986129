<template>
  <div class="table">
    <div class="tableTop">{{name}}</div>
    <table>
      <tbody>
      <tr>
        <td colspan="5" class="solid" style="text-align: justify">
          {{text}}
        </td>
      </tr>
      <tr>
        <td :rowspan="dataPadding[0].dataList.length+1" class="solid">{{data[0].name}}</td>
        <td class="solid">年月</td>
        <td class="solid" >销售收入(万元)</td>
        <td class="solid" >进项税额(万元)</td>
        <td class="solid" >应纳税额(万元)</td>
      </tr>
      <tr v-for="(row,index) in dataPadding[0].dataList" :key="index">
        <td  style="text-align: center">{{row.yearMonth}}</td>
        <td style="text-align: right">{{row.salesMoney}}</td>
        <td style="text-align: right">{{row.taxMoney}}</td>
        <td style="text-align: right">{{row.taxPayable}}</td>
      </tr>
      <tr>
        <td
          :rowspan="dataPadding[1].dataList.length+1" class="solid">{{data[1].name}}
        </td>
        <td class="solid">年度</td>
        <td class="solid">销售收入(万元)</td>
        <td class="solid">应纳税所得额（万元）</td>
        <td class="solid">应纳税额(万元)</td>
      </tr>
      <tr v-for="(row,index) in dataPadding[1].dataList" :key="index+'a'">
        <td  style="text-align: center">{{row.yearMonth}}</td>
        <td style="text-align: right">{{row.salesMoney}}</td>
        <td style="text-align: right">{{row.taxMoney}}</td>
        <td style="text-align: right">{{row.taxPayable}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
import tableMix from "@/mix/TableMix";
import {cloneDeep} from "lodash";

export default {
  name: "TaxBasis",
  mixins:[
    tableMix
  ],
  props:{
    name:String,
    text:String,
    data:Array
  },
  computed:{
    dataPadding(){
      const ret = cloneDeep(this.data)
      if(ret[0].dataList.length===0){
        ret[0].dataList=[{
          "taxPayable": "",
          "yearMonth": "",
          "salesMoney": "",
          "taxMoney": ""
        }]
      }
      if(ret[1].dataList.length===0){
        ret[1].dataList=[{
          "taxPayable": "",
          "yearMonth": "",
          "salesMoney": "",
          "taxMoney": ""
        }]
      }
      return ret;
    }
  }
}
</script>

<style scoped>
.solid{
  text-align: center;
}
</style>
